function formatDate(dateInput) {
    let date;
    if (typeof dateInput === 'string') {
        date = new Date(dateInput);
    } else if (dateInput instanceof Date) {
        date = dateInput;
    } else {
        throw new TypeError('Invalid date format');
    }

    const options = { month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

const formatNumberWithTwoDecimals = (num) => {
    return num.toFixed(2);
}

const formatNumberToCurrency = (num) => {
    let formattedNumber
    try {
        formattedNumber = new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(num);
    }
    catch (error) {
        console.error(error);
    }
    return formattedNumber;
}

const buildUserAddress = (address) => {

    let addressString = address.house_no + ", " + address.street + ", " + address.barangay + ", " + address.city + ", " + address.province + ", " + address.zip_code;
    return addressString;
}

export { formatDate, formatNumberWithTwoDecimals, formatNumberToCurrency, buildUserAddress};