import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import './AddClient.css';
import {registerUser} from 'services/ClientService'


const AddClient = () => {

    const [formData, setFormData] = useState({
        full_name: '',
        store_name: '',
        email: '',
        mobile_number: '',
        password: '',
        confirm_password: '',
        address: {
          house_no: '',
          street:'',
          barangay: '',
          city: '',
          province: '',
          zip_code: ''
        }
      });
    
    
      const toast = useRef(null);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        if (name in formData.address) {
          setFormData({
            ...formData,
            address: { ...formData.address, [name]: value }
          });
        } else {
          setFormData({ ...formData, [name]: value });
        }
      };
    
      const isFormValid = () => {
        // Check if all fields are filled
        for (const key in formData) {
          if (typeof formData[key] === 'object') {
            for (const innerKey in formData[key]) {
              if (!formData[key][innerKey]) {
                return false;
              }
            }
          } else {
            if (!formData[key]) {
              return false;
            }
          }
        }
        
        // Check if passwords match
        if (formData.password !== formData.confirm_password) {
          return false;
        }
    
        return true;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please fill all fields and ensure the passwords match.', life: 3000 });
          return;
        }
    
        try {
            // Make API call to add user
            let signupResult = {success: false, message: "Failed to register user. Please contact support."}
            let userDetails = formData
            delete userDetails.confirm_password

            
            signupResult = await registerUser(userDetails)
            if(!signupResult.success) {

                throw new Error(signupResult.message);
            }
            // Handle success
            toast.current.show({
                severity: 'success',
                summary: 'User Added',
                detail: 'User has been successfully added.'
            });
    
            // Reset form data
            setFormData({
                full_name: '',
                store_name: '',
                email: '',
                mobile_number: '',
                password: '',
                confirm_password: '',
                address: {
                  house_no: '',
                  street:'',
                  barangay: '',
                  city: '',
                  province: '',
                  zip_code: ''
                }
            });
        } catch (error) {
            console.log(error.message);
            // Handle server error
            toast.current.show({
                severity: 'error',
                summary: 'Server Error',
                detail: error.message? error.message : 'An error occurred while adding the user. Please try again later.'
            });
        }
        
        
      };
    
      return (
        <div className="signup-form">
          <Toast ref={toast} />
    
          <form onSubmit={handleSubmit}>
            <h1 style={{ color: 'crimson' }}>Sign Up</h1>

            <InputText name="full_name" value={formData.full_name} onChange={handleChange} placeholder="Full Name" />
            <InputText name="store_name" value={formData.store_name} onChange={handleChange} placeholder="Store Name" />
            <InputText name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
            <InputText name="mobile_number" value={formData.mobile_number} onChange={handleChange} placeholder="Mobile Number" />
            <Password name="password" value={formData.password} onChange={handleChange} placeholder="Password" toggleMask />
            <Password name="confirm_password" value={formData.confirm_password} onChange={handleChange} placeholder="Confirm Password" toggleMask />

            <h2 style={{ color: 'crimson' }}>Address</h2>
            <InputText name="house_no" value={formData.address.house_no} onChange={handleChange} placeholder="House No." />
            <InputText name="street" value={formData.address.street} onChange={handleChange} placeholder="Street" />
            <InputText name="barangay" value={formData.address.barangay} onChange={handleChange} placeholder="Barangay" />
            <InputText name="city" value={formData.address.city} onChange={handleChange} placeholder="City" />
            <InputText name="province" value={formData.address.province} onChange={handleChange} placeholder="Province" />
            <InputText name="zip_code" value={formData.address.zip_code} onChange={handleChange} placeholder="Zip Code" />

    
            <Button label="Submit" className="p-button-rounded p-button-danger" />
          </form>
        </div>
      );

}

export default AddClient;