const ShortcutKeys = {
    MOVEUP: 'ArrowUp',
    MOVEDOWN: 'ArrowDown',
    INCREMENT: '+',
    DECREMENT: '-',
    DELETE: 'Delete',
    FOCUS_BARCODE: 'Escape',
    FOCUS_PRODUCT_NAME_INPUT: 'F1',
    ENTER: 'Enter',
    FOCUS_CASH_INPUT: 'Control'
}

export default ShortcutKeys;