
import React, { createContext, useContext } from 'react';
import io from 'socket.io-client';
import config from '../config/config';
import { getToken } from '../utils/tokenUtils';

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const socket = io(`${config.serverUrl}/admin-socket`,{query: {token: getToken()}})
  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  return useContext(SocketContext);
};

export { SocketProvider, useSocket };
