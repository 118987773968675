import ReactDOM from 'react-dom'
import { useState, useEffect, useRef } from "react";
//prime react
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Checkbox } from "primereact/checkbox";
//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap, faBarcode, faPenToSquare, faIndustry, faCheck } from '@fortawesome/free-solid-svg-icons'
import {getInventoryProductById, updateProduct} from 'services/InventoryService'


const EditProduct =  ({productId, products, setProducts}) => { 

    

    const [targetProduct, setTargetProduct] = useState({});
    const [barcodeString, setBarcodeString] = useState("");
    
    useEffect(() => {
        const fetchProduct = async () => {
            const product = await getInventoryProductById(productId);
            setTargetProduct(product);
            setBarcodeString(product.Barcode.join(","))
        }
        fetchProduct()
    }, [])


    const toast = useRef(null);
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const formElements = event.target.elements;
        const productUpdateData = {
            ProductId: targetProduct.ProductId,
            ProductName: formElements.product_name.value,
            Barcode: formElements.barcode.value,
            CategoryName: formElements.category.value,
            SubCategoryName: formElements.sub_category.value,
            Description: formElements.description.value,
            Manufacturer: formElements.manufacturer.value,
            Cost: parseFloat(formElements.product_cost.value.toString().replace(/,/g, '')) || 0,
            Price: parseFloat(formElements.price.value.toString().replace(/,/g, '')) || 0,
            UOM: formElements.uom.value,
            hasStock: formElements.hasStock.checked
        }
        const updateProductResult = await updateProduct(productUpdateData);
        if (updateProductResult.success) { 
            toast.current.show({ severity: 'success', summary: 'Product Update Success', detail: updateProductResult.message });
            //update the product list
            const updatedProducts = products.map(product => {
                if (product.ProductId === productUpdateData.ProductId) {
                    return productUpdateData;
                } else {
                    return product;
                }
            })
            setProducts(updatedProducts);

        } else {
            toast.current.show({ severity: 'error', summary: 'Product Update Failed', detail: updateProductResult.message });
        }

    }

    const handleNameChange = (event) => {
        setTargetProduct({...targetProduct, ProductName: event.target.value})
    }
    const handleBarcodeChange = (event) => {
        setBarcodeString(event.target.value)
    }
    const handleCategoryChange = (event) => {
        setTargetProduct({...targetProduct, CategoryName: event.target.value})
    }
    const handleSubCategoryChange = (event) => {
        setTargetProduct({...targetProduct, SubCategoryName: event.target.value})
    }
    const handleDescriptionChange = (event) => {
        setTargetProduct({...targetProduct, Description: event.target.value})
    }
    const handleManufacturerChange = (event) => {
        setTargetProduct({...targetProduct, Manufacturer: event.target.value})
    }
    const handleCostChange = (event) => {
        if ( !isNaN(event.value)) {
            setTargetProduct({...targetProduct, Cost: event.value})
        }
    }
    const handlePriceChange = (event) => {
        //if valid number
        if ( !isNaN(event.value)) {
            setTargetProduct({...targetProduct, Price: event.value})
        }
    }

    const handleUomChange = (event) => {
        setTargetProduct({...targetProduct, UOM: event.target.value})
    }

    const handleHasStockChange = (event) => {
        setTargetProduct({...targetProduct, hasStock: event.target.checked})
    }

    // const updateProduct = async () => {
    //     const updateProductResult = await updateProduct(targetProduct);
    //     if (updateProductResult.success) { 
    //         toast.current.show({ severity: 'success', summary: 'Product Update Success', detail: updateProductResult.message });
    //     } else {
    //         toast.current.show({ severity: 'error', summary: 'Product Update Failed', detail: updateProductResult.message });
    //     }
    // }
    return(
        <div>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-column md:flex-row gap-3">
                <Toast ref={toast} position="bottom-center" />
                    <div className="p-inputgroup flex-3" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faSitemap} />
                            <span style={{marginLeft : 5}}> Product</span>
                        </span>
                        <InputText id="product_name" placeholder="Product Name" value={targetProduct.ProductName} onChange={(e) =>{handleNameChange(e)}}/>
                    </div>
                    <div className="p-inputgroup flex-1" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faBarcode} />
                            <span style={{marginLeft : 5}}> Barcode</span>
                        </span>
                        <InputText required id="barcode" placeholder="Barcode(s)" value={barcodeString} onChange={(e) =>{handleBarcodeChange(e)}} />
                    </div>
                    <div className="p-inputgroup flex-1" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Category</span>
                        </span>
                        <InputText id="category" placeholder="Category" value={targetProduct.CategoryName} onChange={(e) =>{handleCategoryChange(e)}} />
                    </div>
                    <div className="p-inputgroup flex-1" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Sub Category</span>
                        </span>
                        <InputText id="sub_category" placeholder="Sub Category" value={targetProduct.SubCategoryName} onChange={(e) =>{handleSubCategoryChange(e)}} />
                    </div>
                    <div className="p-inputgroup flex-1" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Description</span>
                        </span>
                        <InputText id="description" placeholder="Description" value={targetProduct.Description} onChange={(e) =>{handleDescriptionChange(e)}} />
                    </div>
                    <div className="p-inputgroup flex-1" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faIndustry} />
                            <span style={{marginLeft : 5}}> Manufacturer</span>
                        </span>
                        <InputText id="manufacturer" placeholder="Manufacturer" value={targetProduct.Manufacturer} onChange={(e) =>{handleManufacturerChange(e)}} />
                    </div>

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <span>UOM</span>
                        </span>
                        <InputText value={targetProduct.UOM} id="uom" onChange={(e) => handleUomChange(e)} />
                    </div>

                    <div className="p-inputgroup flex-1" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <span>Cost</span>
                        </span>
                        <InputNumber inputId="product_cost" placeholder="Cost" minFractionDigits={2} maxFractionDigits={2} value={targetProduct.Cost} onChange={(e) =>{handleCostChange(e)}}/>
                    </div>
                    <div className="p-inputgroup flex-1" style={{marginBottom:5}}>
                        <span className="p-inputgroup-addon">
                            <span>Price</span>
                        </span>
                        <InputNumber inputId="price" placeholder="Price" minFractionDigits={2} maxFractionDigits={2} value={targetProduct.Price} onChange={(e) =>{handlePriceChange(e)}} />
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="has_stock" name="hasStock" value="Has Stock" onChange={(e)=> handleHasStockChange(e)} checked={targetProduct.hasStock} />
                        <label htmlFor="has_stock" className="ml-2">Has Stock</label>
                    </div>
                </div>
                <center style={{marginTop:5}}>
                    <Button label="Update Product" icon="pi pi-file-edit" type="submit" severity='warning' />
                </center>
            </form>
        </div>
        )

}

export default EditProduct;