import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, NavLink, useLocation, Navigate  } from 'react-router-dom';
import NavigateIfNotAuthorized from './utils/authUtils';
import Signup from './components/Signup.js';
import Login from './components/Login.js';
import Dashboard from './components/protected_components/dashboard/Dashboard.js';
import ClientList from './components/protected_components/users_feature_components/ClientList';
import ProductList from './components/protected_components/inventory_feature_components/Product/ProductList/ProductList.js';
import Inventory from './components/protected_components/inventory_feature_components/Inventory.js';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import CategoryList from './components/protected_components/inventory_feature_components/Category/CategoryList/CategoryList.js';
import SubCategoryList from './components/protected_components/inventory_feature_components/SubCategory/SubCategoryList.js';
import Orders from './components/protected_components/order_feature_components/orders/Orders';

import PendingOrders from './components/protected_components/order_feature_components/pending_orders/PendingOrders';
import ProcessingOrders from './components/protected_components/order_feature_components/processing_orders/ProcessingOrders';
import TransitOrders from './components/protected_components/order_feature_components/transit_orders/TransitOrders';
import CompletedOrders from './components/protected_components/order_feature_components/completed_orders/CompletedOrders';
import CancelledOrders from './components/protected_components/order_feature_components/cancelled_orders/CancelledOrders';


import MainCounter from './components/protected_components/counter/MainCounter/MainCounter.js';
import MainComponent from 'components/protected_components/sales/MainComponent/MainComponent';
import { useSocket } from './socket/SocketContext';





function PrivateRoute() {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isServerConnected, setIsServerConnected] = useState(false);
  const socket = useSocket();

  useEffect(() => {

    socket.on('connect', () => {
      setIsServerConnected(true)
      console.log('Connected to server')
    })

    socket.on('disconnect', () => {
      setIsServerConnected(false)
    })

    socket.on('client-no-auth', () => {

    })

  }, []);

  useEffect(() => {
    
  }, []);

  if (isAuthenticated === false) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } 
  return <Outlet />; 
}

function App() {
  return (
    <PrimeReactProvider >
      <Router >
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />

          <Route element={<PrivateRoute/>}>
            <Route path="/dashboard" element={<Dashboard />}>
            <Route path="counter" element={<MainCounter />} />
            <Route path="sales" element={<MainComponent />} />
            <Route path="inventory" element={<Inventory />} >
              <Route path="products" element={<ProductList />} />
              <Route path="category" element={<CategoryList />} />
              <Route path="subcategory" element={<SubCategoryList />} />
            </Route>
            <Route path="orders" element={<Orders />} >
              <Route path="pending" element={<PendingOrders />} />
              <Route path="processing" element={<ProcessingOrders />} />
              <Route path="transit" element={<TransitOrders />} />
              <Route path="completed" element={<CompletedOrders />} />
              <Route path="cancelled" element={<CancelledOrders />} />
            </Route>
            <Route path="clients" element={<ClientList />} />
      
          </Route>
          <Route path="*" element={< Login/>} />

          </Route>
        </Routes>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
