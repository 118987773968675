import React, { useEffect, useState } from 'react'
import { getOrders, completeOrder } from 'services/OrderService'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import OrderController from '../orders/OrderController/OrderController';
import OrderDisplayTable from '../orders/OrderDisplayTable/OrderDisplayTable';
import './CompletedOrders.css'

const CompletedOrders = () => {
    const [completedOrders, setCompletedOrders] = useState([])
    const [viewOrderVisible, setViewOrderVisible] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    useEffect(() => {
        // Fetch pending orders from the server
        const fetchCompletedOrders = async () => {
            const result = await getOrders('COMPLETED')
            if (result.success) {
                setCompletedOrders(result.orders)
            }
        }

        fetchCompletedOrders()
    }, [])

    const viewOrderHandler = (orderId) => {
        setSelectedOrder(orderId)
        setViewOrderVisible(true)
    }

    const OrderControllerActions = () => {
        return(
        <div className='order_actions'>
        </div>
        )
    }

    const ActionTemplate = (rowData) => {
        return (
            <div>
                <Button label="View" icon="pi pi-eye" onClick={ ()=> {viewOrderHandler(rowData.OrderId)}} style={{marginRight:'10px'}}  />
                <Button label="Print Reciept" icon="pi pi-print" onClick={ ()=> {}} severity='secondary'/>
            </div>
        )
    }


    return (
        
        <div className='orders_content'>
            <div className='completed_orders_title'>Completed Orders</div>
            <Dialog header="Order Details" visible={viewOrderVisible} position={"center"} style = {{width:'50vw', height: '70vh'}}onHide={() => setViewOrderVisible(false)}>
                <OrderController orderId={selectedOrder} orderControllerActions={OrderControllerActions} />
            </Dialog>
              <OrderDisplayTable orderData={completedOrders} ActionTemplate={ActionTemplate} /> 
        </div>
    )
}

export default CompletedOrders;