import config from "../config/config"

/* 
    Modify Sales Records
    This service will be responsible in handling operations related to sales.
*/

const submitOrder = async (orderDetails) => {
    const response = await fetch(`${config.serverUrl}/sales/submit-order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({orderDetails})
    })
    const data = await response.json()
    return data
}


const getSalesRecords = async (startDate, endDate) => {
    const response = await fetch(`${config.serverUrl}/sales/get-sales-report?startDate=${startDate}&endDate=${endDate}`);
    const data = await response.json();

    if(data.success) {
        return data
    }

    return data;
};

const getSalesReportData = async (orderId) => {
    const response = await fetch(`${config.serverUrl}/sales/get-sales-report-data?orderId=${orderId}`);
    const data = await response.json();

    if(data.success) {
        return data
    }

    return data;
}


export {submitOrder, getSalesRecords, getSalesReportData}