// authStatus.js (custom hook)
import { useEffect, useState } from 'react';
import checkAuthentication from '../utils/clientUtils';

const useAuthStatus = () => {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const fetchAuthenticationStatus = async () => {
      const isAuthenticated = await checkAuthentication();
      setAuthenticated(isAuthenticated);
    };

    fetchAuthenticationStatus();
  }, []);

  return authenticated;
};

export default useAuthStatus;
