import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import AddCategory from '../AddCategory/AddCategory';
import { getCategories } from '../../../../../services/InventoryService';

const CategoryList = () => {
    const [categories, setCategories] = useState([]);
    const [addCategoryDialogVisible, setAddCategoryDialogVisible] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            const categories = await getCategories();
            setCategories(categories);
        }
        fetchCategories();

    }, []);

    return (
        <div>
            <div>
                <h2>Categories</h2>
                <Button label="Add Category" icon="pi pi-plus" className="p-button-success" onClick={() => setAddCategoryDialogVisible(true)} />
                <Dialog header="Add Category" visible={addCategoryDialogVisible} maximizable position={"top"} style={{ width: '50vw' }} onHide={() => setAddCategoryDialogVisible(false)}>
                    <AddCategory />
                </Dialog>

            </div>
            <div className="card" style={{marginTop:20}}>
                <DataTable value={categories} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="Name" header="Name"></Column>
                    <Column field="Description" header="Description"></Column>
                </DataTable>
            </div>
        </div>
    );
}

export default CategoryList;
