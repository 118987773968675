import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import AddSubCategory from './AddSubCategory';
import { getCategories, getSubCategories } from 'services/InventoryService';

const SubCategoryList = () => {
    const [subCategories, setSubCategories] = useState([]);
    const [addSubCategoryDialogVisible, setAddSubCategoryDialogVisible] = useState(false);

    useEffect(() => {
        const fetchSubCategories = async () => {
            const subCategories = await getSubCategories();
            const parentCategories = await getCategories();

            subCategories.forEach((subCategory) => {
                const parentCategory = parentCategories.find((parentCategory) => parentCategory.CategoryId === subCategory.ParentCategoryId);
                subCategory.ParentCategory = parentCategory.Name;
            });

            setSubCategories(subCategories);
        }
        fetchSubCategories();
    }, []);

    return (
        <div>
            <div>
                <h2>Sub Categories</h2>
                <Button label="Add Sub Category" icon="pi pi-plus" className="p-button-success" onClick={() => setAddSubCategoryDialogVisible(true)} />
                <Dialog header="Add Sub Category" visible={addSubCategoryDialogVisible} maximizable position={"top"} style={{ width: '50vw' }} onHide={() => setAddSubCategoryDialogVisible(false)}>
                    <AddSubCategory />
                </Dialog>
            </div>
            <div className="card" style={{marginTop:20}}>
                <DataTable value={subCategories} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="Name" header="Name"></Column>
                    <Column field="ParentCategory" header="Parent Category"></Column>
                    <Column field="Description" header="Description"></Column>
                </DataTable>
            </div>
        </div>
    );
}

export default SubCategoryList;
