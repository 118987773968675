import React, { useState } from 'react';
import axios from 'axios';
import config from 'config/config';
const Signup = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirm_password: '',
        user_type: 'Wholesaler', // Set a default user type
      });
    
      const [errorMessage, setErrorMessage] = useState('');
      const [displayError, setDisplayError] = useState('none');
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          // Send a POST request to your server with the form data
          const response = await axios.post(config.serverUrl + '/signup', formData, { withCredentials: true });
          if (response.data.success) {
            return;
          }
          setErrorMessage(response.data.message);
          setDisplayError('block');
    
          // Optionally, redirect the user or perform other actions upon successful signup
        } catch (error) {
          // Handle errors, set error messages, etc.
          console.error('Error:', error);
          setErrorMessage('An error occurred during sign-up.');
          setDisplayError('block');
        }
      };

      return (
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="card">
                <div className="card-header bg-danger text-white">
                  <h2 className="text-center">Dropsell</h2>
                </div>
                <div className="card-body">
                  <form action="/signup" method="POST" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="username">Username:</label>
                      <input type="text" id="username" name="username" className="form-control" value={formData.username} onChange={handleChange} required />
                    </div>
      
                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                      <input type="password" id="password" name="password" className="form-control" value={formData.password} onChange={handleChange}  required />
                    </div>
      
                    <div className="form-group">
                      <label htmlFor="confirm_password">Confirm Password:</label>
                      <input type="password" id="confirm_password" className="form-control" required />
                    </div>
      
                    <div className="form-group">
                      <label htmlFor="user_type">User type:</label>
                      <select id="user_type" name="user_type" className="form-control" value={formData.user_type} onChange={handleChange}  required>
                        <option value="Wholesaler">Wholesaler</option>
                        <option value="Retailer">Retailer</option>
                      </select>
                    </div>
      
                    <button type="submit" className="btn btn-danger btn-block">
                      Sign Up
                    </button>
      
                    <div className="alert alert-danger" id="error-message" style={{ display: displayError }}>
                      {errorMessage}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      
};

export default Signup;
