
import { useEffect, useState } from 'react';
import { getDropsellClients } from 'services/ClientService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AddClient from './AddClient';
import { Dialog } from 'primereact/dialog';
const ClientList = () => {
    const[clients, setClients] = useState([])
    const [showAddClient, setShowAddClient] = useState(false);

    useEffect(() => {
        const fetchClients = async () => {
           const fetchedClients = await getDropsellClients()
           setClients(fetchedClients);
        };

        try {
            fetchClients();
        } catch (error) {
            console.error(error);
        }
    }, []);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        full_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        store_name: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
    
        // Use matchMode based on your requirements
        _filters['global'].value = value;
    
        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };


    const header = renderHeader();


    return(<div>
        <h1>Clients</h1>
        <Button label="Add Client" icon="pi pi-plus" className="p-button-success" onClick={() => setShowAddClient(true)} />
        <Dialog header="Add Client" visible={showAddClient} maximizable position={"top"} style={{ width: '50vw' }} onHide={() => setShowAddClient(false)}>
            <AddClient />
        </Dialog>
        
        <div className="card" style={{marginTop:20}}>
            <DataTable value={clients} tableStyle={{ minWidth: '50rem' }} 
                header={header} filters={filters} onFilter={(e) => setFilters(e.filters)}
                paginator rows={10} dataKey="client_id" filterDisplay="row" emptyMessage="No client found." 
                selectionMode="single"
            >
                <Column field="full_name" filter filterPlaceholder="Search by name" sortable header="Full Name"></Column>
                <Column field="store_name" filter filterPlaceholder="Search by store name" sortable header="Store Name"></Column>
                <Column field="email" filter filterPlaceholder="Search by email" sortable header="Email"></Column>
                <Column field="mobile_number" filter filterPlaceholder="Search by mobile number" sortable header="Mobile #"></Column>
            </DataTable>
            </div>
        </div>)
}

export default ClientList