import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import SalesOrder from '../SalesOrder/SalesOrder';
const MainComponent = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const renderOverview = () => (
        <div>
            <h2>Sales Overview</h2>
            <p>This is where your sales overview will be displayed.</p>
        </div>
    );


    return (
        <div className="card">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Overview">
                    {renderOverview()}
                </TabPanel>
                <TabPanel header="Orders">
                    <SalesOrder />
                </TabPanel>
            </TabView>
        </div>
    );
};

export default MainComponent;
