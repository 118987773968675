import config from '../config/config';
import {getToken} from './tokenUtils'

const checkAuthentication = async () => {
    try {

        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return false; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/api/check-authentication`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            return true;
        } else {
            return false; // Not authenticated
        }
    } catch (error) {

      return false; // Error or not authenticated
    }
  };
  
  export default checkAuthentication;
  