import { useEffect, useState, useRef, forwardRef } from 'react';
import { formatNumberToCurrency } from 'utils/FormatUtils';
import ShortcutKeys from '../MainCounter/ShortcutKeys';
import './ProductController.css';

const ProductController = forwardRef(({ productItems, setProductItems, selectedProductIndex, setSelectedProductIndex },ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {
        if (productItems.length > 0) {
            setSelectedProductIndex(0);
        }
    }, []);

    useEffect(() => {
        if (scrollRef.current) {
            const container = scrollRef.current;
            const item = container.children[selectedProductIndex];
            if (item) {
                const containerRect = container.getBoundingClientRect();
                const itemRect = item.getBoundingClientRect();
                
                if (itemRect.bottom > containerRect.bottom) {
                    container.scrollBy({
                        top: itemRect.bottom - containerRect.bottom + 1, // Scroll down to make room
                        behavior: 'auto'
                    });
                } else if (itemRect.top < containerRect.top) {
                    container.scrollBy({
                        top: itemRect.top - containerRect.top - 1, // Scroll up to make room
                        behavior: 'auto'
                    });
                }
            }
        }
    }, [selectedProductIndex, productItems]);

    const disableScroll = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isFocused && productItems.length > 0) {
                if (event.key === ShortcutKeys.MOVEDOWN && selectedProductIndex < productItems.length - 1) {
                    setSelectedProductIndex(prevIndex => prevIndex + 1);
                } else if (event.key === ShortcutKeys.MOVEUP && selectedProductIndex > 0) {
                    setSelectedProductIndex(prevIndex => prevIndex - 1);
                } else if (event.key === ShortcutKeys.INCREMENT) {
                    const updatedItems = [...productItems];
                    updatedItems[selectedProductIndex].quantity += 1;
                    updatedItems[selectedProductIndex].subTotal = (updatedItems[selectedProductIndex].price * updatedItems[selectedProductIndex].quantity).toFixed(2);;
                    setProductItems(updatedItems);
                } else if (event.key ===  ShortcutKeys.DECREMENT && productItems[selectedProductIndex].quantity > 1) {
                    const updatedItems = [...productItems];
                    updatedItems[selectedProductIndex].quantity -= 1;
                    updatedItems[selectedProductIndex].subTotal = (updatedItems[selectedProductIndex].price * updatedItems[selectedProductIndex].quantity).toFixed(2);
                    setProductItems(updatedItems);
                } else if (event.key ===  ShortcutKeys.DELETE) {
                    const updatedItems = [...productItems];
                    updatedItems.splice(selectedProductIndex, 1);
                    setProductItems(updatedItems);

                    if (updatedItems.length > 0) {
                        setSelectedProductIndex(prevIndex => Math.max(0, prevIndex - 1));
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFocused, selectedProductIndex, productItems, setProductItems]);

    return (
        <div
            className="product-controller"
            tabIndex="0"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            ref={ref}
        >
            <div className="product-controller-header">
                <div className="product-controller-header-item">Product</div>
                <div className="product-controller-header-item">Price</div>
                <div className="product-controller-header-item">Quantity</div>
                <div className="product-controller-header-item">Subtotal</div>
            </div>
            <div
                className="product-controller-body"
                onKeyDown={disableScroll}
                ref={scrollRef}
            >
                {productItems.map((product, index) => (
                    <div
                        key={index}
                        className={`product-controller-item ${selectedProductIndex === index ? 'selected' : ''}`}
                        onClick={() => setSelectedProductIndex(index)}
                    >
                        <div className="product-controller-item-name">{product.productName}</div>
                        <div className="product-controller-item-price">{formatNumberToCurrency(product.price)}</div>
                        <div className="product-controller-item-quantity">{product.quantity}</div>
                        <div className="product-controller-item-subtotal">{formatNumberToCurrency(product.subTotal)}</div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default ProductController;
