import { Outlet } from "react-router-dom"
import {useState, useEffect} from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { useNavigate } from "react-router-dom";
import './Orders.css'
const Orders  = () => {
    const navigate = useNavigate();
    const items = [
        { label: 'Pending', icon: 'pi pi-clock', path: '/dashboard/orders/pending'},
        { label: 'Processing', icon: 'pi pi-cog', path: '/dashboard/orders/processing' },
        { label: 'In-Transit', icon: 'pi pi-car', path: '/dashboard/orders/transit' },
        { label: 'Completed', icon: 'pi pi-check', path: '/dashboard/orders/completed' },
        { label: 'Cancelled', icon: 'pi pi-times', path: '/dashboard/orders/cancelled' }
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        navigate(items[activeIndex].path)

    }, [activeIndex])

    return (
        <div className="order_content">
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => {setActiveIndex(e.index)}} />
            <div className="orders_container">
                <Outlet />
            </div>
        </div>
    )
}

export default Orders;