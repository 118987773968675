
import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

const ImageUploaderControl = ({onFilesChange}) => {
    const toast = useRef(null);
    const [files, setFiles] = useState([]);

    const fileUploadRef = useRef(null);
    
    const onTemplateSelect = (e) => {
        let files = e.files;
        handleFilesChange(files);
    };

    const handleFilesChange = (newFiles) => {
        setFiles(newFiles);
    
        // Call the callback function from the parent component
        if (onFilesChange) {
          onFilesChange(newFiles);
        }
      };

    const onTemplateRemove = (file, callback) => {
        setFiles((prevFiles) => {
            let files = [...prevFiles];
            // Use a function in findIndex to correctly find the index of the file
            let index = files.findIndex((f) => f === file);
            if (index !== -1) {
            files.splice(index, 1);
            }
            handleFilesChange(files);
            callback();
            return files;
        });
    
    // Move handleFilesChange and callback inside the setFiles callback to ensure
    // they operate on the updated state


    };

    const onTemplateClear = (e) => {
        let files = e.files;
        handleFilesChange(files);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

            <FileUpload ref={fileUploadRef} name="demo[]" multiple accept="image/*" maxFileSize={100000000000}
                onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} cancelOptions={cancelOptions} />
        </div>
    )
}

export default ImageUploaderControl
        