import config from "../config/config"

const getOrderRequestTotal = async (orderRequest) => {
    //post request to the server to retrieve all product names
    console.log(orderRequest)
    const response = await fetch(`${config.serverUrl}/cashier/get-order-total`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({orderRequest})
    })

    if(response.status === 200){
        return response.json()
    } else {
        return null
    }


}

export {getOrderRequestTotal}