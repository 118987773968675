import React, { useEffect, useState } from 'react'
import { getOrders, transitionOrderState, cancelOrder } from '../../../../services/OrderService'
import './ProcessingOrders.css'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import OrderController from '../orders/OrderController/OrderController';
import OrderDisplayTable from '../orders/OrderDisplayTable/OrderDisplayTable';
import config from '../../../../config/config';

const ProcessingOrders = () => {
    const [processingOrders, setProcessingOrders] = useState([])
    const [viewOrderVisible, setViewOrderVisible] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    useEffect(() => {
        // Fetch pending orders from the server
        const fetchprocessingOrders = async () => {
            const result = await getOrders('PROCESSING')
            if (result.success) {
                setProcessingOrders(result.orders)
            }
            
        }

        fetchprocessingOrders()
    }, [])

    const viewOrderHandler = (orderId) => {
        setSelectedOrder(orderId)
        setViewOrderVisible(true)
    }

    const handlePendingOrderTransition = async (orderId) => {
        const transitionResult = await transitionOrderState(orderId)
        setViewOrderVisible(false)
        setSelectedOrder(null)
        if (transitionResult.success) {
            const updatedOrders = processingOrders.filter(order => order.OrderId !== orderId)
            setProcessingOrders(updatedOrders)
        }
    }

    const handleCancelOrder = async (orderId) => {

        const cancelOrderResult = await cancelOrder(orderId)
        setViewOrderVisible(false)
        setSelectedOrder(null)
        if (cancelOrderResult.success) {
            const updatedOrders = processingOrders.filter(order => order.OrderId !== orderId)
            setProcessingOrders(updatedOrders)
        }

    }

    const [localSocket, setLocalSocket] = useState(null);

    useEffect(() => {
        const newSocket = new WebSocket(config.dropsellWpfWebSocketServer);

        newSocket.onopen = () => {};
        newSocket.onerror = (error) => {};

        setLocalSocket(newSocket);

        return () => {
            newSocket.close();
        };
    }, []);


    const extractCartData = (orderData) => {
        let cartData = [];

        orderData.Cart.forEach(item => {
            const details = item.productDetails;
            console.log(details.ProductName);
            let unitOfMeasurement = '';
            if(item.PriceType == "RETAIL") {
                unitOfMeasurement = details.UOM;
            } else if (item.PriceType == "WHOLESALE") {
                unitOfMeasurement = details.WholeSaleUOM;
            }
            
            const product = {
                ProductName: details.ProductName,
                Quantity: item.Quantity,
                UOM:unitOfMeasurement
            };

            cartData.push(product);
        })

        return cartData;

    }
    const handlePrint= (orderData) => {
        if (localSocket.readyState === WebSocket.OPEN) {
            const printOrderData = {OrderId: orderData.OrderId, CustomerName:orderData.CustomerName, Cart: extractCartData(orderData)}
            const message = JSON.stringify({ command: 'print-order', data: printOrderData });
            localSocket.send(message);
        } else {
            console.error('WebSocket connection is not open.');
        }
    }


    const OrderControllerActions = () => {
        return(
        <div className='order_actions'>
            <Button label="Deliver Order"style = {{margin:'10px'}} onClick={()=>{handlePendingOrderTransition(selectedOrder)}} severity="help" />
            <Button label="Cancel Order" severity="danger" onClick={()=>{handleCancelOrder(selectedOrder)}}/>
        </div>
        )
    }

    const ActionTemplate = (rowData) => {
        return (
            <div>
                <Button label="View" icon="pi pi-eye" onClick={ ()=> {viewOrderHandler(rowData.OrderId)}} style={{marginRight:'10px'}}  />
                <Button label="Print Orders" icon="pi pi-print" onClick={ ()=> {handlePrint(rowData)}} severity='secondary'/>
            </div>
        )
    }


    return (
        
        <div className='orders_content'>
            <div className='processing_orders_title'>Processing Orders</div>
            <Dialog header="Order Details" visible={viewOrderVisible} position={"center"} style = {{width:'50vw', height: '70vh'}}onHide={() => setViewOrderVisible(false)}>
                <OrderController orderId={selectedOrder} orderControllerActions={OrderControllerActions} />
            </Dialog>
              <OrderDisplayTable orderData={processingOrders} ActionTemplate={ActionTemplate} /> 
        </div>
    )
}

export default ProcessingOrders;