import { json } from 'react-router-dom';
import config from '../config/config';
import {getToken} from '../utils/tokenUtils'

const getOrders = async (status) => {
    const result = {success: false, message:"Failed to retrieve orders", orders: []}
    const token = getToken(); // Retrieve the token from localStorage

    if (!token) {
        result.success = false; 
        result.message = "Not authenticated"
        return result; // No token available, not authenticated
    }
    try {
        const url = `${config.serverUrl}/order/get-orders/${status}`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = "Orders retrieved successfully"
            result.orders = responseJson.orders
        }
    } catch (error) {
        console.error(error)
        return result; // Failed to retrieve orders
    }
    finally {
        return result; 
    }
}

const getOrderById = async (orderId) => {
    const result = {success: false, message:"Failed to retrieve order", orders: []}
    const token = getToken(); // Retrieve the token from localStorage

    if (!token) {
        result.success = false; 
        result.message = "Not authenticated"
        return result; // No token available, not authenticated
    }
    try {
        const url = `${config.serverUrl}/order/get-order-by-id/${orderId}`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = "Orders retrieved successfully"
            result.order = responseJson.order
        }
    } catch (error) {
        console.error(error)
        return result; // Failed to retrieve orders
    }
    finally {
        return result; 
    }
}

const transitionOrderState = async (orderId) => {
    const result = {success: false, message:"Failed to transition order", orders: []}
    const token = getToken(); // Retrieve the token from localStorage

    if (!token) {
        result.success = false; 
        result.message = "Not authenticated"
        return result; // No token available, not authenticated
    }
    try {
        const url = `${config.serverUrl}/order/transition-order-state`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({OrderId: orderId})
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = responseJson.message
        }
    } catch (error) {
        console.error(error)
        return result; // Failed to retrieve orders
    }
    finally {
        return result; 
    }
}

const completeOrder = async (orderId) => {
    const result = {success: false, message:"Failed to transition order", orders: []}
    const token = getToken(); // Retrieve the token from localStorage

    if (!token) {
        result.success = false; 
        result.message = "Not authenticated"
        return result; // No token available, not authenticated
    }
    try {
        const url = `${config.serverUrl}/order/complete-order`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({OrderId: orderId})
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = responseJson.message
        }
    } catch (error) {
        console.error(error)
        return result; // Failed to retrieve orders
    }
    finally {
        return result; 
    }
}

const cancelOrder = async (orderId) => {
    const result = {success: false, message:"Failed to cancel order", orders: []}
    const token = getToken(); // Retrieve the token from localStorage

    if (!token) {
        result.success = false; 
        result.message = "Not authenticated"
        return result; // No token available, not authenticated
    }
    try {
        const url = `${config.serverUrl}/order/cancel-order`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({OrderId: orderId})
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = responseJson.message
        }
    } catch (error) {
        console.error(error)
        return result; // Failed to retrieve orders
    }
    finally {
        return result; 
    }
}


export {getOrders, getOrderById, transitionOrderState, completeOrder, cancelOrder};