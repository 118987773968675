import ReactDOM from 'react-dom'
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons'
import {getInventoryProducts} from 'services/InventoryService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Badge from 'react-bootstrap/Badge';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import AddProduct from '../AddProduct/AddProduct';
import EditProduct from '../EditProduct/EditProduct';
import UpdateProductImage from '../UpdateProductImage/UpdateProductImage';
import io from 'socket.io-client';
import config from 'config/config';
import { getToken } from 'utils/tokenUtils';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { deleteProduct } from 'services/InventoryService';
import { Toast } from 'primereact/toast';
import './ProductList.css';
import { formatNumberToCurrency } from 'utils/FormatUtils';

const ProductList =  () => {
    
    const isMobile = window.innerWidth < 768;
    const dialogWidth = isMobile ? '100vw' : '50vw';
    const toast = useRef(null);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        barcode: { value: null, matchMode: FilterMatchMode.CUSTOM },
    });
    const [addProductDialogVisible, setAddProductVisible] = useState(false);
    const [editProductDialogVisible, setEditProductDialogVisible] = useState(false);
    const [editProductHeader, setEditProductHeader] = useState("Edit Product: ")
    const [updateProductImageDialogVisible, setUpdateProductImageDialogVisible] = useState(false);
    const [updateProductImageHeader, setUpdateProductImageHeader] = useState("Update Product Image: ")
    
    const setEditProductDialog = (productName) => { 
        setEditProductHeader( <div>Edit Product: <Badge bg="danger">{productName}</Badge> </div>)
        setEditProductDialogVisible(true)
    }

    const setUpdateProductImageDialog = (productName) => {
        setUpdateProductImageHeader( <div>Update Product Image: <Badge bg="danger">{productName}</Badge> </div>)
        setUpdateProductImageDialogVisible(true)
    }

    useEffect(() => {
        const fetchProducts = async () => {
           const fetchedProducts = await getInventoryProducts()
            setProducts(fetchedProducts);
        };

        try {
            fetchProducts();
        } catch (error) {
            console.error(error);
        }
    }, []);

    const imageBodyTemplate = (rowData) => {
        return (
            <div style={{width :'60px', height : '60px'}}>
                <Image preview  width="60px" height="60px" src={`${config.serverUrl}/api/image/`+rowData.ProductId}  className="product-image" />
            </div>
        );
    }
    const priceTemplate = (rowData) => {
        return (
            <div className="product-list-price-container">
                <div className="product-list-price">{formatNumberToCurrency(rowData.Price)}</div>
            </div>
        );
    }
    
    const barcodeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                {typeof rowData.Barcode === 'string' ? (
                    <Badge bg="danger" style={{ marginRight: 2 }}>
                    {rowData.Barcode}
                    </Badge>
                ) : (
                    rowData.Barcode.map((barcode, index) => (
                    <Badge key={index} bg="danger" style={{ marginRight: 2 }}>
                        {barcode}
                    </Badge>
                    ))
                )}
            </div>

        );
    }

    const accept = async () => {

        //delete product in backend
        const deleteProductResult = await deleteProduct(selectedProduct.ProductId);

        if (deleteProductResult.success) {

            //delete product in frontend
            const productIndex = products.findIndex(product => product.ProductId === selectedProduct.ProductId);
            products.splice(productIndex, 1);
            setProducts([...products]);

            toast.current.show({ severity: 'success', summary: 'Product Delete Success', detail: deleteProductResult.message });
        } else {
            toast.current.show({ severity: 'error', summary: 'Product Delete Failed', detail: deleteProductResult.message });
        }

        
    }

    const confirmDelete = (productName) => {
        confirmDialog({
            message: `Do you want to delete this ${productName}`,
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept
        });
    };

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
    
        // Use matchMode based on your requirements
        _filters['global'].value = value;
    
        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };

    // const updateProductData = (newProductData) => {
    //     const productIndex = products.findIndex(product => product.ProductId === newProductData.ProductId);
    //     const updatedProducts = [...products]
    //     if (productIndex !== -1) {
    //         // Create a new array with the updated product
    //         updatedProducts[productIndex] = newProductData
    //     }
    //     console.log(updatedProducts)

    //     setProducts(updatedProducts);
    // }
    const header = renderHeader();

    useEffect(() => {
    //   socket.on('user-authentication', (data) => {

    //   });

    //   socket.on('add-product', (data) => {
    //     setProducts((prevProducts) => {
    //         const newProducts = [...prevProducts, data];
    //         return newProducts;
    //       });
    //   });

    //   socket.on('update-product', (newProductData) => {
    //     setProducts(prevProducts => {
    //         const productIndex = prevProducts.findIndex(product => product.ProductId === newProductData.ProductId);
    //         const updatedProducts = [...prevProducts];
    //         if (productIndex !== -1) {
    //             updatedProducts[productIndex] = newProductData;
    //         }
    //         return updatedProducts;
    //     });
    //   });


  
    //   return () => {
    //     socket.off('add-product');
    //     socket.off('user-authentication');
    //     socket.off('update-product');
    //   };
    }, []);

    return (
        <div>
            <ConfirmDialog/>
            <Toast ref={toast} position="bottom-center" />
          <div>
            <h2>Products <FontAwesomeIcon icon={faBoxesStacked} /></h2>
            <Button  onClick={() => {setAddProductVisible(true)}} style={{marginRight:2}}>Add Product</Button>
            {selectedProduct && (
                <div className="selected-buttons-container">
                <Button severity="warning"  onClick={() => {setEditProductDialog(selectedProduct.ProductName)}}>Edit Product</Button>
                <Button severity="help" onClick={() => {setUpdateProductImageDialog(selectedProduct.ProductName)}}>Update Product Image</Button>
                <Button severity="danger" onClick={ () => {confirmDelete(selectedProduct.ProductName)}}>Delete Product</Button>
                </div>
            )}
            <Dialog header="Add Product" visible={addProductDialogVisible} position={"top"} maximizable style={{ width: dialogWidth }} onHide={() => setAddProductVisible(false)}>
                <AddProduct products={products} setProducts = {setProducts} />
            </Dialog>
            <Dialog header={editProductHeader} visible={editProductDialogVisible} position={"top"} maximizable style={{ width: dialogWidth }} onHide={() => setEditProductDialogVisible(false)}>
                <EditProduct productId={selectedProduct?.ProductId} products={products} setProducts = {setProducts}/>
            </Dialog>
            <Dialog header={updateProductImageHeader}  visible={updateProductImageDialogVisible} position={"top"} maximizable style={{ width: dialogWidth }} onHide={() => setUpdateProductImageDialogVisible(false)}>
                <UpdateProductImage productId={selectedProduct?.ProductId} setDialogVisible={setUpdateProductImageDialogVisible} products={products} setProducts = {setProducts}/>
            </Dialog>
            
            <div className="card" style={{marginTop:20}}>
            <DataTable value={products} tableStyle={{ width: '100%' }} 
                header={header}
                paginator rows={10} dataKey="ProductId" filterDisplay="row" emptyMessage="No product found." 
                filters={filters} onFilter={(e) => setFilters(e.filters)}
                selectionMode="single" selection={selectedProduct} onSelectionChange={e => setSelectedProduct(e.value)}
            >
                <Column header="Image" body={imageBodyTemplate}></Column>
                <Column field="ProductName" filter filterPlaceholder="Search by name" sortable header="Product"></Column>
                <Column header="Price" body={priceTemplate} sortable></Column>
                <Column field="UOM"  header="UOM" sortable></Column>
            </DataTable>
            </div>
          </div>
        </div>
      );
}

export default ProductList