import React, { useEffect, useState } from 'react'
import { getOrders, completeOrder } from 'services/OrderService'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import OrderController from '../orders/OrderController/OrderController';
import OrderDisplayTable from '../orders/OrderDisplayTable/OrderDisplayTable';
import './CancelledOrders.css'

const CancelledOrders = () => {
    const [cancelledOrders, setCancelledOrders] = useState([])
    const [viewOrderVisible, setViewOrderVisible] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    useEffect(() => {
        // Fetch pending orders from the server
        const fetchCancelledOrders = async () => {
            const result = await getOrders('CANCELLED')
            if (result.success) {
                setCancelledOrders(result.orders)
            }
        }

        fetchCancelledOrders()
    }, [])

    const viewOrderHandler = (orderId) => {
        setSelectedOrder(orderId)
        setViewOrderVisible(true)
    }

    const OrderControllerActions = () => {
        return(
        <div className='order_actions'>
        </div>
        )
    }

    const ActionTemplate = (rowData) => {
        return (
            <div>
                <Button label="View" icon="pi pi-eye" onClick={ ()=> {viewOrderHandler(rowData.OrderId)}} style={{marginRight:'10px'}}  />
            </div>
        )
    }


    return (
        
        <div className='orders_content'>
            <div className='cancelled_orders_title'>Cancelled Orders</div>
            <Dialog header="Order Details" visible={viewOrderVisible} position={"center"} style = {{width:'50vw', height: '70vh'}}onHide={() => setViewOrderVisible(false)}>
                <OrderController orderId={selectedOrder} orderControllerActions={OrderControllerActions} />
            </Dialog>
              <OrderDisplayTable orderData={cancelledOrders} ActionTemplate={ActionTemplate} /> 
        </div>
    )
}

export default CancelledOrders;