import { useRef, useState, useEffect } from "react";
//prime react
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from "primereact/checkbox";
//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap, faBarcode, faPenToSquare, faIndustry } from '@fortawesome/free-solid-svg-icons'

import {addProduct, addProductImage} from 'services/InventoryService'
import ImageUploaderControl from '../../../../shared/ImageUploaderControl';

import { getCategories, getSubCategories } from 'services/InventoryService';
import { Dropdown } from "primereact/dropdown";
import { Dialog } from 'primereact/dialog';
import Html5QrcodePlugin from 'components/shared/BarcodeScanner';

const AddProduct =  ({products, setProducts}) => {
    const [imageFiles, setImageFiles] = useState([]);
    const [value, setValue] = useState('');
    const [uomItems, setUomItems] = useState([]);
    const [categoryItems, setCategoryItems] = useState([]);
    const [subcategoryItems, setSubcategoryItems] = useState([]);

    const [selectedCategoryItem, setSelectedCategoryItem] = useState(null);
    const [selectedSubCategoryItem, setSelectedSubCategoryItem] = useState(null);

    const [loadedSubCategory, setLoadedSubCategory] = useState(null);
    const [sellingTypes, setSellingTypes] = useState([]);
    const [selectedSellingType, setSelectedSellingType] = useState("Wholesale");
    const [hasStock, setHasStock] = useState(true);
    const uomOptions = ["Pcs", "KG", "L" ]
    const sellingTypesOptions = ["Retail", "Wholesale"];

    const [barcodeString, setBarcodeString] = useState("");
    const [barcodeScannerVisible, setBarcodeScannerVisible] = useState(false);
    const handleBarcodeScan = (decodedText, decodedResult) => {
        setBarcodeString(decodedText)
        setBarcodeScannerVisible(false);
    }

    const handleFilesChange = (files) => {
      // Do something with the files in the parent component
      setImageFiles(files);
    };

    const search = (event) => {
        setUomItems([...uomOptions]);
    }


    useEffect(() => {
        // This will be triggered whenever imageFiles changes
        
    }, [imageFiles]);

    useEffect(() => {
        const fetchSubCategories = async () => {
            const subCategories = await getSubCategories();
            const parentCategories = await getCategories();

            subCategories.forEach((subCategory) => {
                const parentCategory = parentCategories.find((parentCategory) => parentCategory.CategoryId === subCategory.ParentCategoryId);
                subCategory.ParentCategory = parentCategory.Name;
            });

            setCategoryItems(parentCategories);
            setSubcategoryItems(subCategories);
        }
        fetchSubCategories();
    }, []);

    const onCategoryChange = (e) => {
        //get subcategories where the parent category is the selected category
        if (e.value) {
            const subCategories = subcategoryItems.filter((subCategory) => subCategory.ParentCategory === e.value.Name);
            setLoadedSubCategory(subCategories);
            setSelectedCategoryItem(e.value);
        }
    }

    const toast = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Access form elements and gather values
        const formElements = event.target.elements;

        const newFormData = {
            ProductName: formElements.product_name.value,
            Barcode: formElements.barcode.value,
            CategoryName: selectedCategoryItem.Name,
            SubCategoryName: selectedSubCategoryItem.Name,
            Description: formElements.description.value,
            Manufacturer: formElements.manufacturer.value,
            UOM: formElements.uom.value,
            Cost: parseFloat(formElements.product_cost.value.toString().replace(/,/g, '')) || 0,
            Price: parseFloat(formElements.price.value.toString().replace(/,/g, '')) || 0,
            SellingType: formElements.selling_type.value,
            hasStock: formElements.has_stock.checked,
        };

        setImageFiles(async (prevImageFiles) => {
            // Make sure prevImageFiles is an array before calling addProductImage
            const filesToAdd = Array.isArray(prevImageFiles) ? prevImageFiles : [];
        
            const addProductResult = await addProduct(newFormData);
        
            if (addProductResult.success) {
              const addProductImageResult = await addProductImage(filesToAdd, addProductResult.product.ProductId);
              if (!addProductImageResult.success) {
                toast.current.show({
                  severity: 'warn',
                  summary: 'Product added but images upload failed.',
                  detail: addProductResult.message,
                });
                return;
              }
              toast.current.show({
                severity: 'success',
                summary: 'Product Add Success',
                detail: addProductResult.message,
              });

                // Update the product list
                setProducts([addProductResult.product, ...products]);
            } else {
              toast.current.show({
                severity: 'error',
                summary: 'Product Add Failed',
                detail: addProductResult.message,
              });
            }
          });
    };

    return (
        <div>
            <Dialog header="Scan Barcode" visible={barcodeScannerVisible} position={"center"} maximizable style={{ width: '100vw' }} onHide={() => setBarcodeScannerVisible(false)}>
                <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={handleBarcodeScan}
                />
            </Dialog>
            <form onSubmit={handleSubmit}>
                <div className="card flex flex-column md:flex-row gap-3">
                <Toast ref={toast} position="bottom-center" />
                    <div className="p-inputgroup flex-3">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faSitemap} />
                            <span style={{marginLeft : 5}}> Product</span>
                        </span>
                        <InputText id="product_name" placeholder="Product Name"/>
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faBarcode} />
                            <span style={{marginLeft : 5}}> Barcode</span>
                        </span>
                        <InputText required id="barcode" placeholder="Barcode(s)" value={barcodeString} />
                        <Button type="button" icon="pi pi-camera" onClick={() => setBarcodeScannerVisible(true)} />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Category</span>
                        </span>
                        <Dropdown inputId='category' value={selectedCategoryItem} onChange={e => onCategoryChange(e)} options={categoryItems} optionLabel="Name" placeholder="Select Category" />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Sub Category</span>
                        </span>
                        <Dropdown inputId='sub_category' value={selectedSubCategoryItem} onChange={e => setSelectedSubCategoryItem(e.value)} options={loadedSubCategory} optionLabel="Name" placeholder="Select Sub Category"
                         disabled={!loadedSubCategory || loadedSubCategory.length === 0} />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Description</span>
                        </span>
                        <InputText id="description" placeholder="Description" />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faIndustry} />
                            <span style={{marginLeft : 5}}> Manufacturer</span>
                        </span>
                        <InputText id="manufacturer" placeholder="Manufacturer" />
                    </div>

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <span>Cost</span>
                        </span>
                        <InputNumber inputId="product_cost" placeholder="Cost" minFractionDigits={2} maxFractionDigits={2} />
                    </div>

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <span>UOM</span>
                        </span>
                        <AutoComplete value={value} inputId="uom" suggestions={uomItems} completeMethod={search} onChange={(e) => setValue(e.value)} />
                    </div>

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <span>Selling Type</span>
                        </span>
                        <Dropdown value={selectedSellingType} onChange={(e) => setSelectedSellingType(e.value)} options={sellingTypesOptions} inputId="selling_type" placeholder="Select a Selling Type"/>
                    </div>

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <span>Price</span>
                        </span>
                        <InputNumber inputId="price" placeholder="Price" minFractionDigits={2} maxFractionDigits={2} />
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="has_stock" name="hasStock" value="Has Stock" onChange={(e)=> setHasStock(e.checked)} checked={hasStock} />
                        <label htmlFor="has_stock" className="ml-2">Has Stock</label>
                    </div>
                    <div className="p-inputgroup flex-1">
                        <ImageUploaderControl onFilesChange={handleFilesChange}/>
                    </div>
                </div>
                <center style={{marginTop:5}}>
                    <Button label="Add Product" type="submit" />
                </center>
            </form>
        </div>
    );
}


export default AddProduct;