import React from 'react';
import { Outlet } from 'react-router-dom';

const Inventory = () => (
  <div>
    <h1>Inventory</h1>

    <Outlet />
  </div>
);

export default Inventory;
