import React, { useEffect, useState } from 'react'
import { getOrders, transitionOrderState, cancelOrder, getOrderById } from 'services/OrderService'

import './PendingOrders.css'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import OrderController from '../orders/OrderController/OrderController';
import OrderDisplayTable from '../orders/OrderDisplayTable/OrderDisplayTable';
import { useSocket } from 'socket/SocketContext';


const PendingOrders = () => {
    const [pendingOrders, setPendingOrders] = useState([])
    const [viewOrderVisible, setViewOrderVisible] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const socket = useSocket()

    const addNewPendingOrder = async (orderId) => {
        const newOrderResult = await getOrderById(orderId)
        if (newOrderResult.success) {
            setPendingOrders( (currentPendingOrders) =>
             {
                const orderExists = currentPendingOrders.some(order => order.OrderId === orderId)
                console.log(currentPendingOrders)
                console.log(orderExists)
                if (!orderExists) {
                    return[...currentPendingOrders, newOrderResult.order]
                }
                return currentPendingOrders
                }
             )
        }
    }

    useEffect(() => {
        socket.on('new-order-submitted', (orderId) => {
            //check first if the order is already exists before adding it to the list
            const orderExists = pendingOrders.some(order => order.OrderId === orderId)
            if (!orderExists) {
                addNewPendingOrder(orderId)
            }
        })

    }, [])


    useEffect(() => {
        // Fetch pending orders from the server
        const fetchPendingOrders = async () => {
            const result = await getOrders('pending')
            if (result.success) {
                setPendingOrders(result.orders)
            }
        }

        fetchPendingOrders()
    }, [])

    const viewOrderHandler = (orderId) => {
        setSelectedOrder(orderId)
        setViewOrderVisible(true)
    }

    const handlePendingOrderTransition = async (orderId) => {
        const transitionResult = await transitionOrderState(orderId)
        setViewOrderVisible(false)
        setSelectedOrder(null)
        if (transitionResult.success) {
            const updatedOrders = pendingOrders.filter(order => order.OrderId !== orderId)
            setPendingOrders(updatedOrders)
        }
    }

    const handleCancelOrder = async (orderId) => {

        const cancelOrderResult = await cancelOrder(orderId)
        setViewOrderVisible(false)
        setSelectedOrder(null)
        if (cancelOrderResult.success) {
            const updatedOrders = pendingOrders.filter(order => order.OrderId !== orderId)
            setPendingOrders(updatedOrders)
        }

    }
    const OrderControllerActions = () => {
        return(
        <div className='order_actions'>
            <Button label="Process Order"style = {{margin:'10px'}} onClick={()=>{handlePendingOrderTransition(selectedOrder)}}/>
            <Button label="Cancel Order" severity="danger" onClick={()=>{handleCancelOrder(selectedOrder)}}/>
        </div>
        )
    }

    const ActionTemplate = (rowData) => {
        return (
            <div>
                <Button label="View" icon="pi pi-eye" onClick={ ()=> {viewOrderHandler(rowData.OrderId)}} />
            </div>
        )
    }

    return (
        
        <div className='orders_content'>
            <div className='pending_orders_title'>Pending Orders</div>
            <Dialog header="Order Details" visible={viewOrderVisible} position={"center"} style = {{width:'50vw', height: '70vh'}}onHide={() => setViewOrderVisible(false)}>
                <OrderController orderId={selectedOrder} orderControllerActions={OrderControllerActions} />
            </Dialog>
              <OrderDisplayTable orderData={pendingOrders} ActionTemplate={ActionTemplate} /> 
        </div>
    )
}

export default PendingOrders;