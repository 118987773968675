import { useEffect, useState } from 'react';
import {getSalesRecords, getSalesReportData} from 'services/SalesService';
import { Calendar } from 'primereact/calendar';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { formatNumberToCurrency, formatDate } from 'utils/FormatUtils';
import './SalesOrder.css';

const SalesOrder = () => {
    const dateToday = new Date();
    const dateTomorrow = new Date();
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);

    const [salesReport, setSalesReport] = useState([]);
    const [startDate, setStartDate] = useState(dateToday);
    const [endDate, setEndDate] = useState(dateTomorrow);
    const [refresh, setRefresh] = useState(0);
    const [totalSales, setTotalSales] = useState(0);

    useEffect(() => {

        const fetchSalesReport = async () => {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);            
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            
            const salesRecords = await getSalesRecords(formattedStartDate, formattedEndDate);
            if(salesRecords.success) {
                setSalesReport(salesRecords.salesReport);
            } else {
                setSalesReport([]);
            }
            
        }

        fetchSalesReport();


    }, [startDate, endDate, refresh]);

    useEffect(() => {
        const calculateTotalSales = () => {
            let total = 0;
            salesReport.forEach((report) => {
                total += report.total;
            });
            setTotalSales(total);
        }
        calculateTotalSales();
    }, [salesReport]);


    //data table 
    const [selectedReport, setSelectedReport] = useState(null);
    const orderDateDisplay = (rowData) => {
        //add date and time
        return formatDate(rowData.dateSubmitted);

    }

    const orderTotalDisplay = (rowData) => {
        return formatNumberToCurrency(rowData.total);
    }

    //selected report
    const [selectedReportData, setSelectedReportData] = useState(null);
    useEffect(() => {
        console.log(salesReport)
        const fetchSalesReportData = async () => {
            if (selectedReport == null || salesReport.length === 0) {
                setSelectedReportData(null);
                return;
            }
            const orderData = await getSalesReportData(selectedReport.orderId);
            if(orderData.success) {
                setSelectedReportData(orderData.data);
            }
        }
        fetchSalesReportData();
    }, [selectedReport, salesReport]);

    const priceBodyTemplate = (rowData) => {
        return formatNumberToCurrency(rowData.price);
    }
    const subTotalBodyTemplate = (rowData) => {
        return formatNumberToCurrency(rowData.subtotal);
    }
    

    return (
        <div>
            <Splitter style={{ height: '100vh' }}>
                <SplitterPanel className="flex align-items-center justify-content-center" size={60}>
                    <div className='sales-controller'>
                        <div className="date-control-container">
                            <div className="card flex flex-wrap gap-3 p-fluid">
                                <div className="flex-auto">
                                    <label htmlFor="buttondisplay" className="font-bold block mb-2">
                                        Start Date
                                    </label>
                                    <Calendar id="buttondisplay" value={startDate} onChange={(e) => setStartDate(e.value)}  showIcon />
                                </div>
                                <div className="flex-auto">
                                    <label htmlFor="buttondisplay" className="font-bold block mb-2">
                                        End Date
                                    </label>

                                    <Calendar value={endDate} onChange={(e) => setEndDate(e.value)}  showIcon  />
                                </div>

                            </div>
                            <div className="search-controls">
                                <div className="search-inputs">
                                    <InputText placeholder="Search by Order ID" type="text" className="p-inputtext-sm" />
                                    <Button label="Search" className="p-button-sm p-button-rounded p-button-primary" />
                                </div>
                                <div className="search-inputs">
                                    <InputText placeholder="Search by Customer" type="text" className="p-inputtext-sm" />
                                    <Button label="Search" className="p-button-sm p-button-rounded p-button-primary" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button label="Refresh" icon="pi pi-refresh" onClick={() => setRefresh(refresh + 1)} />
                            <div>Total : {formatNumberToCurrency(totalSales)}</div>
                        </div>
                        <div className="sales-report-table-container">
                            <DataTable value={salesReport} selectionMode="single" selection={selectedReport} onSelectionChange={(e) => setSelectedReport(e.value)}>
                                <Column field="orderId" header="Order ID" />
                                <Column field="customerName" header="Customer Name" />
                                <Column field="dateSubmitted" body={orderDateDisplay} header="Order Date" />
                                <Column field="total" body={orderTotalDisplay} header="Total Amount" />
                            </DataTable>
                        </div>
                    </div>
                </SplitterPanel>
                <SplitterPanel className="flex align-items-center justify-content-center" size={40}>
                    {selectedReportData ? (
                    <div className="order-detail-controls">
                        <div className="order-details-summary">
                            <div className='order-major-details'>
                                <div className="order-info major-info">
                                    <div>
                                        Order ID
                                    </div>
                                    <div>
                                        {selectedReportData?.orderId}
                                    </div>
                                </div>
                                <div className="order-info average-info">
                                    <div>
                                        Customer Name: 
                                    </div>
                                    <div>
                                        {selectedReportData?.customerName}
                                    </div>
                                </div>
                                <div className="order-info average-info">
                                    <div>
                                        Date: 
                                    </div>
                                    <div>
                                        {formatDate(selectedReportData?.dateSubmitted)}
                                    </div>
                                </div>
                                <Button label="Re-Print Reciept" className="p-button-sm p-button-rounded p-button-primary" />
                            </div>
                            <div className='order-minor-details'>
                                <div className="order-info average-info">
                                    <div>
                                        Total:
                                    </div>
                                    <div className="highlight-info">
                                        {formatNumberToCurrency(selectedReportData?.total)}
                                    </div>
                                </div>
                                <div className="order-info minor-info">
                                    <div>
                                        Cash Recieved:
                                    </div>
                                    <div>
                                        {formatNumberToCurrency(selectedReportData?.cashReceived)}
                                    </div>
                                </div>
                                <div className="order-info minor-info">
                                    <div>
                                       Change:
                                    </div>
                                    <div>
                                        {formatNumberToCurrency(selectedReportData?.change)}
                                    </div>
                                </div>
                                <div className="order-info minor-info">
                                    <div>
                                        Total Products:
                                    </div>
                                    <div>
                                        {selectedReportData?.totalProducts}
                                    </div>
                                </div>
                                <div className="order-info minor-info">
                                    <div>
                                        Total Items:
                                    </div>
                                    <div>
                                        {selectedReportData?.totalItems}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="order-items">
                            <DataTable value={selectedReportData.items} className="p-datatable-sm">
                                <Column field="productName" header="Name" sortable></Column>
                                <Column field="quantity" header="Quantity" sortable></Column>
                                <Column field="price" header="Price" body={priceBodyTemplate} sortable></Column>
                                <Column field="subtotal" header="Sub Total" body={subTotalBodyTemplate} sortable></Column>
                            </DataTable>
                        </div>
                    </div>
                    ): (<div> <h2>No record selected</h2></div>)}
                </SplitterPanel>
            </Splitter>

        </div>
    );
}

export default SalesOrder;