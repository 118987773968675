import { json } from 'react-router-dom';
import config from '../config/config';
import {getToken} from 'utils/tokenUtils'

const getInventoryProducts = async () => {
    try {
        const result = {success: false, message:"Failed to retrieve products", products: []}
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            result.success = false; 
            result.message = "Not authenticated"
            return result; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/get-products`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            return responseJson.products;
        } else {
            return null; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }
}

//get categories
const getCategories = async () => {
    try {
        const result = {success: false, message:"Failed to retrieve categories", categories: []}
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            result.success = false; 
            result.message = "Not authenticated"
            return result; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/get-categories`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            return responseJson.categories;
        } else {
            return null; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }
}

//get subcategories
const getSubCategories = async () => {
    try {
        const result = {success: false, message:"Failed to retrieve subcategories", subcategories: []}
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            result.success = false; 
            result.message = "Not authenticated"
            return result; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/get-subcategories`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            return responseJson.subCategories;
        } else {
            return null; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }

}



const getInventoryProductById = async (productId) => {
    try {
        const result = {success: false, message:"Failed to retrieve products", products: []}
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            result.success = false; 
            result.message = "Not authenticated"
            return result; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/get-product-by-id/${productId}`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            return responseJson.product;
        } else {
            return null; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }
}

const addProduct = async (productDetails) => {
    const result = {success: false, message:"Failed to add product. Please contact support."}
    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return false; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/add-product`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(productDetails),
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success
            result.message = responseJson.message
            result.product = responseJson.product
            return result
        } else {
            result.success = responseJson.success
            result.message = responseJson.message
            return result; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      result.success = false
      result.message = "Failed to add product. Please contact support."
      return result
    }
}

const addCategory = async (categoryDetails) => {
    const result = {success: false, message:"Failed to add category. Please contact support."}
    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return false; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/add-category`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryDetails),
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success
            result.message = responseJson.message
            result.category = responseJson.category
            return result
        } else {
            result.success = responseJson.success
            result.message = responseJson.message
            return result; // Not authenticated
        }
    } catch (error) {
        result.success = false
        result.message = "Failed to add category. Please contact support."
        return result
    }

}

const addSubCategory = async (subCategoryDetails) => {
    const result = { success: false, message: "Failed to add subcategory. Please contact support." };
    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return result; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/add-subcategory`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(subCategoryDetails),
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success;
            result.message = responseJson.message;
            result.subCategory = responseJson.subCategory; // Assuming the response includes the subcategory
            return result;
        } else {
            result.success = responseJson.success;
            result.message = responseJson.message;
            return result;
        }
    } catch (error) {
        console.error('Error adding subcategory:', error);
        result.message = "Failed to add subcategory. Please contact support.";
        return result;
    }
}

const updateProduct = async (productDetails) => {
    const result = {success: false, message:"Failed to update product. Please contact support."}
    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return false; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/update-product`

        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(productDetails),
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success
            result.message = responseJson.message
            return result
        } else {
            result.success = responseJson.success
            result.message = responseJson.message
            return result; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      result.success = false
      result.message = "Failed to add product. Please contact support."
      return result
    }

}

const deleteProduct = async (productId) => {
    const result = {success: false, message:"Failed to delete product. Please contact support."}
    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return false; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/inventory/delete-product/${productId}`
        const response = await fetch(url, {
            method: 'DELETE',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success
            result.message = responseJson.message
            return result
        } else {
            result.success = responseJson.success
            result.message = responseJson.message
            return result; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      result.success = false
      result.message = "Failed to delete product. Please contact support."
      return result
    }
}

const addProductImage = async (imageFiles, productId) => {
    const result = { success: false, message: "Failed to upload images. Please contact support." };

    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return result; // No token available, not authenticated
        }

        const formData = new FormData();

        // Append each image file to the FormData
        formData.append(`fileId`, productId);
        for (let i = 0; i < imageFiles.length; i++) {
            formData.append(`imageFile`, imageFiles[i]);
        }

        const url = `${config.serverUrl}/api/upload-image`;
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const responseJson = await response.json();

        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success;
            result.message = responseJson.message;
        } else {
            console.log(responseJson.message)
            result.message = responseJson.message || "Failed to upload images. Please contact support.";
        }

    } catch (error) {
        console.error(error);
        result.message = "Failed to upload images. Please contact support.";
    }

    return result;
};

const replaceImage = async (imageFiles, productId) => {
    const result = { success: false, message: "Failed to replace images. Please contact support." };

    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return result; // No token available, not authenticated
        }

        const formData = new FormData();

        // Append the productId (as fileId) and image files to the FormData
        formData.append('fileId', productId);
        for (let i = 0; i < imageFiles.length; i++) {
            formData.append('imageFile', imageFiles[i]);
        }

        const url = `${config.serverUrl}/api/replace-image`;
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });

        const responseJson = await response.json();

        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success;
            result.message = responseJson.message;
        } else {
            console.log(responseJson.message);
            result.message = responseJson.message || "Failed to replace images. Please contact support.";
        }

    } catch (error) {
        console.error(error);
        result.message = "Failed to replace images. Please contact support.";
    }

    return result;
};


const addCategoryImage = async (imageFiles, categoryId) => {
    const result = { success: false, message: "Failed to upload category images. Please contact support." };

    try {
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            return result; // No token available, not authenticated
        }

        const formData = new FormData();

        // Append each image file to the FormData
        formData.append('fileId', categoryId);
        imageFiles.forEach(file => {
            formData.append('imageFile', file);
        });

        const url = `${config.serverUrl}/api/upload-image`; // Adjust the URL according to your API endpoint
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const responseJson = await response.json();

        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success;
            result.message = responseJson.message;
        } else {
            console.log(responseJson.message)
            result.message = responseJson.message || "Failed to upload category images. Please contact support.";
        }
    } catch (error) {
        console.error(error);
        result.message = "Failed to upload category images. Please contact support.";
    }

    return result;
};

const getAllProductNames = async () => {
    const response = await fetch(`${config.serverUrl}/inventory/get-all-product-names`)
    const data = await response.json()
    return data.productNames
}

const getProductDataByName = async (productName) => {
    const encodedProductName = encodeURIComponent(productName);
    const response = await fetch(`${config.serverUrl}/inventory/get-product-by-name/${encodedProductName}`)
    const data = await response.json()
    return data.product
}

const getProductByBarcode = async (barcode) => {
    const response = await fetch(`${config.serverUrl}/inventory/get-product-by-barcode/${barcode}`)
    const data = await response.json()
    return data.product
}




export {getInventoryProducts, addProduct, updateProduct, getInventoryProductById, addProductImage, replaceImage, addCategory, addSubCategory,deleteProduct,
        getCategories, getSubCategories, getAllProductNames, getProductDataByName, getProductByBarcode,
        addCategoryImage
    }