import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import useAuthStatus from '../hooks/authStatus';
import config from 'config/config';
import { saveToken } from 'utils/tokenUtils';

const Login = () => {
  const authenticated = useAuthStatus();

  const navigate = useNavigate();
  useEffect(() => {
    if (authenticated) {
      navigate('/dashboard/inventory/products')
    }
  }, [authenticated, navigate]);

  const [loginResponse, setLoginResponse] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const formElement = event.target; // Get the form element
    const formData = {
      username: formElement.username.value,
      password: formElement.password.value,
    };
    

    try {

      const response = await fetch(`${config.serverUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify(formData),
      });
      console.log(config)
      if (response.ok) {
        // Successful login
        let responseData = await response.json()
        saveToken(responseData.token)
        setLoginResponse('Login successful');
        navigate('/dashboard')
        // You can also redirect the user to another page here
      } else {
        // Failed login
        const responseData = await response.json();
        setLoginResponse(responseData.message); // Assuming the server returns an error message
      }
    } catch (error) {
      console.error(error);
      setLoginResponse('An error occurred'); // Handle network or other errors
    }
  };


  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="card">
            <div className="card-header bg-danger text-white">
              <h2 className="text-center">Dropsell</h2>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="username">Username:</label>
                  <input type="text" id="username" name="username" className="form-control" required />
                </div>
  
                <div className="form-group">
                  <label htmlFor="password">Password:</label>
                  <input type="password" id="password" name="password" className="form-control" required />
                </div>
  
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-danger" style={{ width: '150px', margin:'10px' }}>
                    Log In
                  </button>
                  <button
                    className="btn btn-danger ml-2" // Add some margin between the buttons
                    style={{ width: '150px' }}
                    onClick={() => navigate('/signup')}
                  >
                    Sign-Up
                  </button>
                </div>
                {loginResponse && (
                  <div className="alert alert-danger" id="error-message">
                    {loginResponse}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Login;
