import React, { useEffect, useState } from 'react'
import { buildUserAddress, formatDate, formatNumberToCurrency } from 'utils/FormatUtils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './OrderDisplayTable.css'


const OrderDisplayTable = ({orderData, ActionTemplate}) => {

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        barcode: { value: null, matchMode: FilterMatchMode.CUSTOM },
    });
    const [selectedOrder, setSelectedOrder] = useState(null);
    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
    
        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };

    const header = renderHeader()

    const DateTemplate = (rowData) => {
        return (
            <div>
                {formatDate(rowData.PlacedDate)}
            </div>
        )
    }

    const FinalPriceTemplate = (rowData) => {
        return (
            <div>
                {formatNumberToCurrency(rowData.OrderSummary.finalPrice)}
            </div>
        )
    }

    const CustomerAddressTemplate = (rowData) => {

        return (
        
        <div className='user_address'>
            {buildUserAddress(rowData.CustomerAddress)}
        </div>
        
        )
    }


    return (
    <div className='order_container'>
            <DataTable value={orderData} tableStyle={{ minWidth: '50rem' }} 
                header={header}
                dataKey="OrderId" filterDisplay="row" emptyMessage="No orders found." 
                filters={filters} onFilter={(e) => setFilters(e.filters)}
                selectionMode="single" selection={selectedOrder} onSelectionChange={e => setSelectedOrder(e.value)}
            >
                <Column field="OrderId" sortable header="Order Id"></Column>
                <Column field="PlacedDate" body={DateTemplate} sortable header="Order Date"></Column>
                <Column field="CustomerName" sortable header="Customer Name"></Column>
                <Column body={CustomerAddressTemplate} header="Delivery Address"></Column>
                <Column field="OrderSummary.finalPrice" body ={FinalPriceTemplate} sortable header="Total"></Column>
                <Column  body ={ActionTemplate} header="Actions"></Column>
            </DataTable>
    </div>
    
    )

}

export default OrderDisplayTable;