
import React, { useState, useEffect } from 'react'
import { getOrderById } from 'services/OrderService';
import './OrderController.css'
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { formatNumberToCurrency } from 'utils/FormatUtils';
import { Card } from 'primereact/card';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';


const OrderController = ({ orderId, orderControllerActions }) => {

    const [orderData, setOrderData] = useState(null)
    const [cartData, setCartData] = useState(null)

    useEffect(() => {
        const fetchOrder = async () => {
            const orderResult = await getOrderById(orderId)
            setCartData(orderResult.order.Cart)
            setOrderData(orderResult.order);
        };

        fetchOrder();
    }, []);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        barcode: { value: null, matchMode: FilterMatchMode.CUSTOM },
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
    
        setFilters(_filters);
    };
    
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };

    const header = renderHeader()

    const PriceTemplate = (rowData) => {

        const returnPrice = () => {

            return rowData.productDetails.Price
        }

        return (
            <div>
                {formatNumberToCurrency(returnPrice())}
            </div>
        )
    }

    const UnitOfMeasureTemplate = (rowData) => {

        const returnUom = () => {

            return rowData.productDetails.UOM
        }

        return (
            <div>
                {returnUom()}
            </div>
        )
    }

    const setStatusBadgeSeverity = (orderState) => {
        if (orderState === "PENDING") {
            return "warning"
        } else if (orderState === "PROCESSING") {
            return "primary"
        } else if (orderState === "IN_TRANSIT") {
            return "help"
        }else if (orderState === "COMPLETED") {
            return "success"
        } else if (orderState === "CANCELLED") {
            return "danger"
        }

    }

    return (
        <div className="order_details_container">
            {orderData && 
                <div className='order_main_details'>

                
                    <Card subTitle="Customer Details"  >
                        <div className="order_details">
                            <Button label={orderData.OrderState} severity={setStatusBadgeSeverity(orderData.OrderState)} ></Button>
                            <div></div>
                            <div>Order #: </div>
                            <div>{orderData.OrderId}</div>
                            <div>Customer: </div>
                            <div>{orderData.CustomerName}</div>
                            <div>Shop Name: </div>
                            <div>{orderData.ShopName}</div>
                        </div>
                    
                    </Card >
                    <Card subTitle="Order Summary"  >
                        <div className="order_details">
                            <div>Total Items: </div>
                            <div>{orderData.OrderSummary.totalItems}</div>
                            <div>Cart Total: </div>
                            <div>{orderData.OrderSummary.cartSubTotal}</div>
                        </div>


                    </Card>
                
                    
                </div>
            }
            {orderControllerActions()}
        
            <Fieldset legend="Cart" toggleable>
               { orderData && <DataTable value={cartData} tableStyle={{ width: '100%', fontSize: '10px'}} 
                    header={header}
                    dataKey={cartData} filterDisplay="row" emptyMessage="No product found." 
                    filters={filters} onFilter={(e) => setFilters(e.filters)}
                >

                    <Column field="productDetails.ProductName" header="Product Name" sortable />
                    <Column field="PriceType" header="Price Type" sortable/>
                    <Column field="Quantity" header="Quantity" sortable/>
                    <Column body={UnitOfMeasureTemplate} header="UOM" />
                    <Column body={PriceTemplate} header="Price Bought" />
                </DataTable>
                }
            </Fieldset>
        </div>



    )
}

export default OrderController