

const constructReciept = (order) => {
    let cartData = [];

        order.items.forEach(item => {
            
            const product = {
                ProductName: item.productName,
                Quantity: item.quantity,
                Price: item.price,
                SubTotal: item.subtotal
            };

            cartData.push(product);
        })

    console.log('Reciept constructed');
    console.log('cartData')
    return cartData;
}

const sendReciept = (order, socket) => {

    const recieptData = constructReciept(order);
    const command = "print-reciept";
    const printRecieptData = {OrderId: order.orderId, CustomerName:order.customerName, CartTotal: order.total, Cart: recieptData}
    const message = JSON.stringify({ command: command, data: printRecieptData });
    socket.send(message);

}


export {sendReciept};