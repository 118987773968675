
// const config = {
//   server:"192.168.100.160:8080",
//   serverUrl: "http://192.168.100.160:8080",
//   dropsellWpfWebSocketServer: "ws://localhost:9876",
//   //counter
//   defaultCustomerName: "Guest",

// };

// export default config;

//for deployment use the one below

const config = {
    serverUrl: window.location.protocol + "//" + window.location.host,
    server: window.location.host,
    dropsellWpfWebSocketServer: "wss://localhost:9876",
    defaultCustomerName: "Guest",
  };

  export default config;
