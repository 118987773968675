import React, { useState, useRef } from 'react'
import './UpdateProductImage.css'
import {Image} from 'primereact/image'
import {Toast} from 'primereact/toast'
import config from 'config/config'
import ImageUploaderControl from '../../../../shared/ImageUploaderControl';
import { Button } from 'primereact/button';
import {replaceImage} from 'services/InventoryService'

const UpdateProductImage = ({ productId, setDialogVisible, products, setProducts }) => {
    const toast = useRef(null);
    const [imageFiles, setImageFiles] = useState([]);
    const handleFilesChange = (files) => {
        // Do something with the files in the parent component
        setImageFiles(files);
      };
    const handleUpdateImage = async () => {
        // Make sure prevImageFiles is an array before calling addProductImage
        setImageFiles(async (prevImageFiles) => {
            const filesToAdd = Array.isArray(prevImageFiles) ? prevImageFiles : [];
            const updateImageResult = await replaceImage(filesToAdd, productId);
            if (updateImageResult.success) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Product Image Update Success',
                });

            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Product Image Update Error',
                });
            }
        });
        setDialogVisible(false);
    }
    return(
        <div>
            <Toast ref={toast} position="bottom-center" />
            <div className="current-image-details">
                <h2>Current Image: </h2>
                <Image width="150px" height="150px" src={`${config.serverUrl}/api/image/${productId}`} alt="Product Image" />
            </div>
            <ImageUploaderControl onFilesChange={handleFilesChange}/>
            <Button onClick={()=>{handleUpdateImage()}}>Update Image</Button>
        </div>
    )
}

export default UpdateProductImage;