import { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { addCategory, addCategoryImage } from '../../../../../services/InventoryService';
import ImageUploaderControl from '../../../../shared/ImageUploaderControl';

const AddCategory = () => {
    const [imageFiles, setImageFiles] = useState([]);
    const toast = useRef(null);

    const handleFilesChange = (files) => {
        setImageFiles(files);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formElements = event.target.elements;

        const newFormData = {
            Name: formElements.category_name.value,
            Description: formElements.description.value,
        };

        const addCategoryResult = await addCategory(newFormData);
        if (addCategoryResult.success) {
            const addCategoryImageResult = await addCategoryImage(imageFiles, addCategoryResult.category.CategoryId);
            if (!addCategoryImageResult.success) {
                toast.current.show({
                    severity: 'warn',
                    summary: 'Category added but images upload failed.',
                    detail: addCategoryResult.message,
                });
                return;
            }
            toast.current.show({
                severity: 'success',
                summary: 'Category Add Success',
                detail: addCategoryResult.message,
            });
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Category Add Failed',
                detail: addCategoryResult.message,
            });
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="card flex flex-column md:flex-row gap-3">
                    <Toast ref={toast} position="bottom-center" />
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faTag} />
                            <span style={{marginLeft : 5}}> Category Name</span>
                        </span>
                        <InputText id="category_name" placeholder="Category Name" />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Description</span>
                        </span>
                        <InputText id="description" placeholder="Description" />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <ImageUploaderControl onFilesChange={handleFilesChange}/>
                    </div>
                </div>
                <center style={{marginTop:5}}>
                    <Button label="Add Category" type="submit" />
                </center>
            </form>
        </div>
    );
}

export default AddCategory;
