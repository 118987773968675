import { useRef, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faPenToSquare, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { getCategories } from 'services/InventoryService';
import { addSubCategory } from 'services/InventoryService';

const AddSubCategory = () => {
    const [parentCategories, setParentCategories] = useState([]);
    useEffect(() => {
        const fetchParentCategories = async () => {
            const fetchedParentCategories = await getCategories();
            setParentCategories(fetchedParentCategories);
        };
        fetchParentCategories();
    }, []);

    const [selectedParentCategory, setSelectedParentCategory] = useState(null);

    const toast = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formElements = event.target.elements;
        console.log(selectedParentCategory)
        const newSubCategoryData = {
            ParentCategoryId: selectedParentCategory.CategoryId,
            Name: formElements.subcategory_name.value,
            Description: formElements.description.value,
        };

        const addSubCategoryResult = await addSubCategory(newSubCategoryData);
        if (addSubCategoryResult.success) {
            toast.current.show({
                severity: 'success',
                summary: 'Sub Category Add Success',
                detail: addSubCategoryResult.message,
            });
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Sub Category Add Failed',
                detail: addSubCategoryResult.message,
            });
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="card flex flex-column md:flex-row gap-3">
                    <Toast ref={toast} position="bottom-center" />
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faSitemap} />
                            <span style={{marginLeft : 5}}> Parent Category</span>
                        </span>
                        <Dropdown inputId='parent_category' value={selectedParentCategory} onChange={e => setSelectedParentCategory(e.value)} options={parentCategories} optionLabel="Name" placeholder="Select Parent Category" />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faTag} />
                            <span style={{marginLeft : 5}}> Sub Category Name</span>
                        </span>
                        <InputText id="subcategory_name" placeholder="Sub Category Name" />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{marginLeft : 5}}> Description</span>
                        </span>
                        <InputText id="description" placeholder="Description" />
                    </div>
                </div>
                <center style={{marginTop:5}}>
                    <Button label="Add Sub Category" type="submit" />
                </center>
            </form>
        </div>
    );
}

export default AddSubCategory;
