import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import useAuthStatus from '../hooks/authStatus';

const NavigateIfNotAuthorized = (endpoint) => {
    const authenticated = useAuthStatus();

    const navigate = useNavigate();
    useEffect(() => {
      if (authenticated === false) {
        navigate(endpoint)
      }
    }, [authenticated, navigate, endpoint]);
}

export default NavigateIfNotAuthorized