import React, { useState } from 'react';
import NavigateIfNotAuthorized from '../../../utils/authUtils';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';



const Dashboard = () => {
  NavigateIfNotAuthorized('/login');
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const getLinkStyle = (path) => ({
    padding: '10px',
    textDecoration: 'none',
    color: 'black',
    display: 'block',
    backgroundColor: location.pathname.startsWith(path) ? '#f0f0f0' : 'transparent',
    fontWeight: location.pathname.startsWith(path) ? 'bold' : 'normal'
  });

  return (
    <div>
      <Sidebar visible={visible} onHide={() => setVisible(false)}>
        <div>
          <NavLink to="/dashboard/inventory" style={getLinkStyle('/dashboard/inventory')}>
            Inventory <FontAwesomeIcon icon={faBoxesStacked} />
          </NavLink>
          <div style={{ paddingLeft: 30 }}>
            <NavLink to="/dashboard/inventory/products" style={getLinkStyle('/dashboard/inventory/products')}>Products</NavLink>
            <NavLink to="/dashboard/inventory/category" style={getLinkStyle('/dashboard/inventory/category')}>Categories</NavLink>
            <NavLink to="/dashboard/inventory/subcategory" style={getLinkStyle('/dashboard/inventory/subcategory')}>Sub Categories</NavLink>
          </div>
        </div>
      </Sidebar>

      <button className="openbtn bg-danger" style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000 }} onClick={() => setVisible(true)}>
        &#9776;
      </button>

      <div style={{ marginTop: 50, marginLeft: 20, marginRight: 20 }}>
        <Outlet /> {/* This will render the component based on the route */}
      </div>
    </div>
  );
}

export default Dashboard;
