import './CheckoutConfirmation.css';
import { useEffect, useState, useRef } from 'react';
import { formatNumberToCurrency } from 'utils/FormatUtils';
import { getOrderRequestTotal } from 'services/CashierService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { submitOrder } from 'services/SalesService';
import config from 'config/config';

const CheckoutConfirmation = ({ items, cashReceived, afterCheckout }) => {
    const [total, setTotal] = useState(0);
    const [change, setChange] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [trueCashReceived, setTrueCashRecieved] = useState(0);
    const [trueItems, setTrueItems] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const checkoutButton = useRef(null);
    useEffect(() => {
        const getOrderTotal = async () => {
            try {
                const orderRequest = {
                    items: items,
                    cashReceived: cashReceived,
                };
                const response = await getOrderRequestTotal(orderRequest);
                if (response) {
                    setTrueCashRecieved(response.cashReceived);
                    setTotal(response.total);
                    setChange(response.change);
                    setTotalItems(response.totalItems);
                    setTotalProducts(response.totalProducts);
                    setTrueItems(response.items);
                    setIsLoaded(true);
                    if(checkoutButton.current) {
                        checkoutButton.current.focus();
                    }
                }
            } catch (error) {
                console.error("Failed to fetch order total", error);
                setIsLoaded(false);
            }
        };

        getOrderTotal();
    }, [items, cashReceived]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && isLoaded) {
                checkout();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

    },[isLoaded]);

    const priceBodyTemplate = (product) => {
        return formatNumberToCurrency(product.price);
    };

    const subTotalBodyTemplate = (product) => {
        return formatNumberToCurrency(product.subtotal);
    };


    //Customer Name Input Control
    const [customerName, setCustomerName] = useState(config.defaultCustomerName);


    const checkout = async () => {
        const orderDetails = {
            customerName: customerName,
            items: trueItems,
            cashReceived: trueCashReceived,
        };
        const result = await submitOrder(orderDetails);

        afterCheckout(result);
    }

    return (
        <>
            {isLoaded ? (
                <div className="checkout-confirmation-container">
                    <div className="checkout-confirmation-header">
                        <div className="major-order-details">
                            <div className="order-details">
                                <div>Total Amount Due: </div>
                                <div className='highlighted-text'>{formatNumberToCurrency(total)}</div>
                            </div>
                            <div className="order-details">
                                <div>Cash Recieved: </div>
                                <div>{formatNumberToCurrency(trueCashReceived)}</div>
                            </div>
                            <div className="order-details">
                                <div>Change: </div>
                                <div>{formatNumberToCurrency(change)}</div>
                            </div>
                        </div>
                        <div className="minor-order-details">
                            <div className="order-details">
                                <div>Total Products: </div>
                                <div>{totalProducts}</div>
                            </div>
                            <div className="order-details">
                                <div>Total Items: </div>
                                <div>{totalItems}</div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-confirmation-body">
                        <DataTable value={trueItems} className="p-datatable-sm">
                            <Column field="productName" header="Name" sortable></Column>
                            <Column field="quantity" header="Quantity" sortable></Column>
                            <Column field="price" header="Price" body={priceBodyTemplate} sortable></Column>
                            <Column field="subtotal" header="Sub Total" body={subTotalBodyTemplate} sortable></Column>
                        </DataTable>
                    </div>
                    <div className="checkout-confirmation-footer">
                        <div>
                            <label>Customer Name:</label>
                            <InputText value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                        </div>
                        <Button ref={checkoutButton} label="Checkout" style={{marginTop: '10px', marginRight: '20px'}} onClick={()=>{checkout()}}/>
                    </div>
                </div>
            ) : (
                <div>Processing Order...</div>
            )}
        </>
    );
};

export default CheckoutConfirmation;
