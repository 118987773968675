import { json } from 'react-router-dom';
import config from '../config/config';
import {getToken} from '../utils/tokenUtils'

const getDropsellClients = async () => {
    try {
        const result = {success: false, message:"Failed to retrieve Clients", products: []}
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            result.success = false; 
            result.message = "Not authenticated"
            return result; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/client/get-clients`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            return responseJson.dropsell_clients;
        } else {
            return null; // Not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }

}

const registerUser = async (user) => {
    try {
        let result = {success: false, message:"Failed to register user"}
        const token = getToken(); // Retrieve the token from localStorage

        if (!token) {
            result.success = false; 
            result.message = "Not authenticated"
            return result; // No token available, not authenticated
        }
        const url = `${config.serverUrl}/client/add-dropsell-user`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });
        const responseJson = await response.json()
        if (response.status === 200 && responseJson.success) {
            result = {success: true, message:"Successfully registered user"}
        } else {
            result = {success: false, message:responseJson.message}
        }
        return result
    } catch (error) {
      console.error(error);
      throw error
    }

}


export {getDropsellClients, registerUser}