import React, { useEffect, useState } from 'react'
import { getOrders, completeOrder, cancelOrder } from 'services/OrderService'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import OrderController from '../orders/OrderController/OrderController';
import OrderDisplayTable from '../orders/OrderDisplayTable/OrderDisplayTable';
import './TransitOrders.css'
import config from 'config/config';

const TransitOrders = () => {
    const [inTransitOrders, setInTransitOrders] = useState([])
    const [viewOrderVisible, setViewOrderVisible] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    useEffect(() => {
        // Fetch pending orders from the server
        const fetchinTransitOrders = async () => {
            const result = await getOrders('IN_TRANSIT')
            if (result.success) {
                setInTransitOrders(result.orders)
            }
        }

        fetchinTransitOrders()
    }, [])

    const viewOrderHandler = (orderId) => {
        setSelectedOrder(orderId)
        setViewOrderVisible(true)
    }

    const handlePendingOrderTransition = async (orderId) => {
        const transitionResult = await completeOrder(orderId)
        setViewOrderVisible(false)
        setSelectedOrder(null)
        if (transitionResult.success) {
            const updatedOrders = inTransitOrders.filter(order => order.OrderId !== orderId)
            setInTransitOrders(updatedOrders)
        }
    }

    const handleCancelOrder = async (orderId) => {

        const cancelOrderResult = await cancelOrder(orderId)
        setViewOrderVisible(false)
        setSelectedOrder(null)
        if (cancelOrderResult.success) {
            const updatedOrders = inTransitOrders.filter(order => order.OrderId !== orderId)
            setInTransitOrders(updatedOrders)
        }

    }

    
    const [localSocket, setLocalSocket] = useState(null);

    useEffect(() => {
        const newSocket = new WebSocket(config.dropsellWpfWebSocketServer);

        newSocket.onopen = () => {};
        newSocket.onerror = (error) => {};

        setLocalSocket(newSocket);

        return () => {
            newSocket.close();
        };
    }, []);


    const extractCartData = (orderData) => {
        let cartData = [];

        orderData.Cart.forEach(item => {
            const details = item.productDetails;
            let unitOfMeasurement = '';
            let price = item.productDetails.Price;
            
            const product = {
                ProductName: details.ProductName,
                Quantity: item.Quantity,
                UOM:unitOfMeasurement,
                Price: price,
                SubTotal: item.Quantity * price
            };

            cartData.push(product);
        })

        return cartData;

    }
    const handlePrint= (orderData) => {
        if (localSocket.readyState === WebSocket.OPEN) {
            const printRecieptData = {OrderId: orderData.OrderId, CustomerName:orderData.CustomerName, CartTotal: orderData.OrderSummary.cartSubTotal, Cart: extractCartData(orderData)}
            const message = JSON.stringify({ command: 'print-reciept', data: printRecieptData });
            localSocket.send(message);
        } else {
            console.error('WebSocket connection is not open.');
        }
    }



    const OrderControllerActions = () => {
        return(
        <div className='order_actions'>
            <Button label="Complete Order"style = {{margin:'10px'}} onClick={()=>{handlePendingOrderTransition(selectedOrder)}} severity="success" />
            <Button label="Cancel Order" severity="danger" onClick={()=>{handleCancelOrder(selectedOrder)}}/>
        </div>
        )
    }

    const ActionTemplate = (rowData) => {
        return (
            <div>
                <Button label="View" icon="pi pi-eye" onClick={ ()=> {viewOrderHandler(rowData.OrderId)}} style={{marginRight:'10px'}}  />
                <Button label="Print Reciept" icon="pi pi-print" onClick={ ()=> {handlePrint(rowData)}} severity='secondary' />
            </div>
        )
    }


    return (
        
        <div className='orders_content'>
            <div className='transit_orders_title'>In-Transit Orders</div>
            <Dialog header="Order Details" visible={viewOrderVisible} position={"center"} style = {{width:'50vw', height: '70vh'}}onHide={() => setViewOrderVisible(false)}>
                <OrderController orderId={selectedOrder} orderControllerActions={OrderControllerActions} />
            </Dialog>
              <OrderDisplayTable orderData={inTransitOrders} ActionTemplate={ActionTemplate} /> 
        </div>
    )
}

export default TransitOrders;