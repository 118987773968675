import { AutoComplete } from 'primereact/autocomplete';
import {Card} from 'primereact/card';
import './MainCounter.css';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import { useEffect, useState, useRef } from 'react';
import ProductController from '../ProductController/ProductController';
import { getAllProductNames, getProductDataByName, getProductByBarcode } from 'services/InventoryService';
import ShortcutKeys from './ShortcutKeys';
import { formatNumberToCurrency } from 'utils/FormatUtils';
import CheckoutConfirmation from '../CheckoutConfirmation/CheckoutConfirmation';
import { Toast } from 'primereact/toast';
import {sendReciept} from 'services/ReceiptService';
import config from 'config/config';
const MainCounter = () => {

    // ProductController
    const productControllerElement = useRef(null);
    const [productItems, setProductItems] = useState([]);
    const [selectedProductIndex, setSelectedProductIndex] = useState(0);

    //Product Name Searcher
    const [productNameValue, setProductNameValue] = useState('');
    const [productNameSuggestions, setProductNameSuggestions] = useState([]);
    const productNameInputRef = useRef(null);
    const [productNames, setProductNames] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const productNames = await getAllProductNames();
            if (productNames) {
                setProductNames(productNames);
            }
        }

        fetchData();

    }, []);


    const productNameKeyPress = async (event) => {
        if (event.key === "Enter" && productNameValue !== '') {
            
            const result = await getProductDataByName(productNameValue);
            if (!result) {
                showError("Product with name " + productNameValue + " not found");
                return;
            }
            let product = {};
            product.productId = result.ProductId;
            product.productName = result.ProductName;
            product.price = result.Price;
            product.quantity = 1;
            product.subTotal = (product.price * product.quantity).toFixed(2);

            //if the product is already in the list, increase the quantity
            let index = productItems.findIndex((item) => item.productId === product.productId);
            if (index !== -1) {
                let updatedItems = [...productItems];
                updatedItems[index].quantity += 1;
                updatedItems[index].subTotal = (updatedItems[index].price * updatedItems[index].quantity).toFixed(2);
                setProductItems(updatedItems);
                setSelectedProductIndex(index);
            } else {
                //insert the product in the begiining of the array
                setProductItems([product, ...productItems]);
                setSelectedProductIndex(0);
            }
            setProductNameValue('');
            productNameInputRef.current.getInput().blur()
            productControllerElement.current.focus();
        }
    }
    
    const search = (event) => {
        // setProductNameSuggestions(productNames.filter((item) => item.toLowerCase().includes(event.query.toLowerCase())));
        //remove whitespace
        setProductNameSuggestions(productNames.filter((item) => item.toLowerCase().replace(/\s/g, '').includes(event.query.toLowerCase().replace(/\s/g, '') )));
    }

    //Barcode Searcher
    const [barcodeValue, setBarcodeValue] = useState('');
    const productBarcodeInputRef = useRef(null);
    const productBarcodeKeyPress = async (event) => {
        if (event.key === "Enter" && '' !== barcodeValue) {
            const result = await getProductByBarcode(barcodeValue);
            if (!result) {
                showError("Product with barcode " + barcodeValue + " not found");
                return;
            }
            let product = {};
            product.productId = result.ProductId;
            product.productName = result.ProductName;
            product.price = result.Price;
            product.quantity = 1;
            product.subTotal = (product.price * product.quantity).toFixed(2);

            //if the product is already in the list, increase the quantity
            let index = productItems.findIndex((item) => item.productId === product.productId);
            if (index !== -1) {
                let updatedItems = [...productItems];
                updatedItems[index].quantity += 1;
                updatedItems[index].subTotal = (updatedItems[index].price * updatedItems[index].quantity).toFixed(2);
                setProductItems(updatedItems);
                setSelectedProductIndex(index);
            } else {
                //insert the product in the begiining of the array
                setProductItems([product, ...productItems]);
                setSelectedProductIndex(0);
            }
            setBarcodeValue('');

        }
        else if (event.key === ShortcutKeys.MOVEDOWN && productItems.length > 0 && selectedProductIndex < productItems.length - 1) {
            setSelectedProductIndex(prevIndex => prevIndex + 1);
        } 
        else if (event.key === ShortcutKeys.MOVEUP && productItems.length > 0 && selectedProductIndex > 0) {
            setSelectedProductIndex(prevIndex => prevIndex - 1);
        }
        else if (event.key === ShortcutKeys.INCREMENT && productItems.length > 0) {
            const updatedItems = [...productItems];
            updatedItems[selectedProductIndex].quantity += 1;
            updatedItems[selectedProductIndex].subTotal = (updatedItems[selectedProductIndex].price * updatedItems[selectedProductIndex].quantity).toFixed(2);
            setProductItems(updatedItems);
            setBarcodeValue('');
        } 
        else if (event.key === ShortcutKeys.DECREMENT && productItems.length > 0 && productItems[selectedProductIndex].quantity > 1 ) {
            const updatedItems = [...productItems];
            updatedItems[selectedProductIndex].quantity -= 1;
            updatedItems[selectedProductIndex].subTotal = (updatedItems[selectedProductIndex].price * updatedItems[selectedProductIndex].quantity).toFixed(2);
            setProductItems(updatedItems);
            setBarcodeValue('');
        } 
        else if (event.key === ShortcutKeys.DELETE && productItems.length > 0 ) {
            const updatedItems = [...productItems];
            updatedItems.splice(selectedProductIndex, 1);
            setProductItems(updatedItems);

            if (updatedItems.length > 0) {
                setSelectedProductIndex(prevIndex => Math.max(0, prevIndex - 1));
            }
        }
    }

    //MainCounter
    const [cashReceived, setCashReceived] = useState(0);
    const [cashChange, setCashChange] = useState(0);
    const [cashTotal, setCashTotal] = useState(0);
    const cashReceivedInputRef = useRef(null);
    useEffect(() => {
        const shortcutHandler = (event) => {
            if (event.key === ShortcutKeys.FOCUS_BARCODE) {
                event.preventDefault();
                productBarcodeInputRef.current.focus();
                setBarcodeValue('');
            }
            else if (event.key === ShortcutKeys.FOCUS_PRODUCT_NAME_INPUT) {
                event.preventDefault();
                productNameInputRef.current.focus();
                setProductNameValue('');
            }
            else if (event.key === ShortcutKeys.FOCUS_CASH_INPUT) {
                event.preventDefault();
                cashReceivedInputRef.current.focus();
                setCashReceived('');
            }
        }


        window.addEventListener('keydown', shortcutHandler);


        return () => {
            window.removeEventListener('keydown', shortcutHandler);
        }


    }, [productBarcodeInputRef, productNameInputRef, cashReceivedInputRef]);

    useEffect(() => {
        const calculateTotal = () => {
            let total = 0;
            productItems.forEach((item) => {
                total += item.price * item.quantity;
            });
            setCashTotal(total.toFixed(2));
            let realCashReceived = cashReceived ? cashReceived : 0;
            setCashChange(realCashReceived - total);

        }
        calculateTotal();
    }, [productItems, cashReceived]);
    const clearAll = () => {
        setProductItems([]);
        setCashReceived(null);
        setCashChange(0);
    }

    const handleCashRecieveKeyDown = (event) => {
        event.preventDefault();
        if (event.key === ShortcutKeys.ENTER) {
            confirmCheckout();
        }
        //if number key is pressed, append the number to the cashReceived
        if (event.key >= 0 && event.key <= 9 ) {
            if (cashReceived == null) {
                setCashReceived(parseFloat(event.key));
                return;
            }
            const updatedCashReceived = cashReceived.toString() + event.key
            //parse the string to number
            setCashReceived(parseFloat(updatedCashReceived));
        }
        //if backspace key is pressed, remove the last digit
        else if (event.key === 'Backspace' && cashReceived != null) {
            if (cashReceived.toString().length === 1) {
                setCashReceived(null);
                return;
            }
            setCashReceived(cashReceived.toString().slice(0, -1));
        }

    }

    //Confirmation Dialog
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const confirmCheckout = () => {
        if (productItems.length === 0) {
            showWarning("Please add products to the list");
            return;
        }

        if (cashReceived == null || cashReceived <= 0 ) {
            showWarning("Please enter cash recieved amount");
            return;
        }

        setDisplayConfirmation(true);
    }

    //print reciept
    const [localSocket, setLocalSocket] = useState(null);
    useEffect(() => {
        const newSocket = new WebSocket(config.dropsellWpfWebSocketServer);

        newSocket.onopen = () => {};
        newSocket.onerror = (error) => {};

        setLocalSocket(newSocket);

        return () => {
            newSocket.close();
        };
    }, []);

    const afterCheckout = (orderResult) => {
        setDisplayConfirmation(false);
        if (orderResult.success) {
            showSuccess('Order Success with Order ID: ' + orderResult.order.orderId);
            sendReciept(orderResult.order, localSocket);
            clearAll();
        } else {
            showError('Order Failed');
        }
    }

    const toast = useRef(null);

    const showSuccess = (message) => {
        toast.current.show({severity:'success', summary: 'Success', detail: message, life: 3000});
    }
    const showWarning = (message) => {
        toast.current.show({severity:'warn', summary: 'Warning', detail: message, life: 3000});
    }
    const showError = (message) => {
        toast.current.show({severity:'error', summary: 'Error', detail: message, life: 3000});
    }


    return (
        <div className="main-ui">
            <Toast ref={toast} position="top-center" />
            <div className='product-controllers'>
                <div className='controller-header'></div>
                <div className='controller-body'>
                    <ProductController ref={productControllerElement} productItems={productItems} setProductItems={setProductItems} selectedProductIndex={selectedProductIndex} setSelectedProductIndex={setSelectedProductIndex}/>
                </div>
                <div className='controller-footer'>
                    <div className='product-search-inputs'>
                        <div id="barcode-search-control-container">
                            <InputText ref={productBarcodeInputRef} placeholder="Barcode" value={barcodeValue} onKeyDown={productBarcodeKeyPress} onChange={(e) => setBarcodeValue(e.target.value)}  />
                        </div>
                        <div id="product-name-search-control-container">
                            <AutoComplete ref={productNameInputRef} value={productNameValue} placeholder="Product Name" suggestions={productNameSuggestions} completeMethod={search} onKeyPress={productNameKeyPress} onChange={(e) => setProductNameValue(e.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='input-controllers'>
                <div className='controller-header'>
                     <Button label="Clear All" severity="danger" onClick={clearAll} />
                </div>
                <div className='controller-body'>
                <Card className='checkout-panel' title="Order Summary">
                    <div className='order-summary'>
                        <div className="order-summary-controller">
                            <label htmlFor="cashRecieveController" className="order-summary-controller-label">Cash Recieved</label>
                            <InputNumber ref={cashReceivedInputRef} inputId="cashRecieveController" value={cashReceived} onKeyDown={handleCashRecieveKeyDown}  maxFractionDigits={2} />
                        </div>
                        <div className='order-summary-total'>
                            <h3>Total</h3>
                            <h3>{formatNumberToCurrency(cashTotal)}</h3>
                        </div>
                        <div className='order-summary-total'>
                            <h4>Change</h4>
                            <h4>{formatNumberToCurrency(cashChange)}</h4>
                        </div>
                        <div className="cash-recieve-clickpad">
                            <div className='cash-recieve-button-row'>
                                <Button className='cash-recieve-button' label="100" onClick={() => setCashReceived(100)} />
                                <Button className='cash-recieve-button' label="200" onClick={() => setCashReceived(200)} />
                                <Button className='cash-recieve-button' label="500" onClick={() => setCashReceived(500)} />
                            </div>
                            <div className='cash-recieve-button-row'>
                                <Button className='cash-recieve-button' label="1000" onClick={() => setCashReceived(1000)} />
                                <Button className='cash-recieve-button' label="5000" onClick={() => setCashReceived(5000)} />
                                <Button className='cash-recieve-button' label="10000" onClick={() => setCashReceived(10000)} />
                            </div>
                        </div>
                    </div>
                </Card>
                </div>
                <div className='checkout-button-container'>
                    <Button className="checkout-button" label="Checkout" severity='success'  onClick={()=>{confirmCheckout()}} />
                </div>
            </div>
            <Dialog header="Confirm Checkout" visible={displayConfirmation} style={{ width: '50vw',height:'80vh' }} onHide={() => {setDisplayConfirmation(false) }}>
                <CheckoutConfirmation items={productItems} cashReceived={cashReceived} afterCheckout={afterCheckout} />
            </Dialog>
        </div>
    );
}

export default MainCounter;